import React from "react";
import "./testdb.css";
// import { Link } from "react-router-dom";

function TestDb() {
  return (
    <div>
      <h1>А</h1>
    </div>
  );
}

export default TestDb;
