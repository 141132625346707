import React, { useState } from "react";

const TokenRefresher = () => {
  const [refreshToken, setRefreshToken] = useState("");
  const [serverResponse, setServerResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setRefreshToken(event.target.value);
  };

  const refreshTokens = async () => {
    try {
      const response = await fetch(
        "https://www.infochemistryweb.ru/api/auth/token/refresh",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Если необходима аутентификация, добавьте заголовок Authorization
            Authorization: `Bearer ${refreshToken}`,
          },
          body: JSON.stringify({ refresh: refreshToken }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setServerResponse(data);
      setError(null); // сброс ошибки
    } catch (err) {
      setServerResponse(null); // сброс ответа
      setError(err.message);
    }
  };

  return (
    <div>
      <h1>Refresh Tokens</h1>
      <input
        type="text"
        value={refreshToken}
        onChange={handleInputChange}
        placeholder="Enter your refresh token"
      />
      <button onClick={refreshTokens}>Refresh</button>

      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {serverResponse && (
        <div>
          <h2>Server Response:</h2>
          <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TokenRefresher;
