import HeaderAcc from "../navbaracc/navbaracc";
import Organic from "../organic/organic";

const OrganicPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Organic />
      </div>
    </div>
  );
};

export default OrganicPage;
