import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./grouping.css";

function Grouping() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const { id } = useParams();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [isPopUpActive, setPopUpActive] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  const handleCheckboxChange = (studentId) => {
    const isSelected = selectedStudents.includes(studentId);
    let updatedSelectedStudents;

    if (isSelected) {
      updatedSelectedStudents = selectedStudents.filter(
        (selectedId) => selectedId !== studentId
      );
    } else {
      updatedSelectedStudents = [...selectedStudents, studentId];
    }

    setSelectedStudents(updatedSelectedStudents);
  };

  const handleSave = async () => {
    try {
      const groupData = {
        name: groupName,
        students: selectedStudents,
      };

      const response = await fetch(
        "https://www.infochemistryweb.ru/api/groups/new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(groupData),
        }
      );

      if (response.status === 401) {
        const refreshToken = localStorage.getItem("refresh_token");
        const refreshResponse = await fetch(
          "https://www.infochemistryweb.ru/api/auth/token/refresh",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        if (refreshResponse.ok) {
          const { access_token } = await refreshResponse.json();
          localStorage.setItem("access_token", access_token);
          handleSave(); // Повторно пытаемся получить данные со студентами
        } else {
          throw new Error("Ошибка при обновлении токена");
        }
      } else if (!response.ok) {
        throw new Error("Ошибка при выполнении запроса");
      }

      if (response.ok) {
        console.log("Данные успешно отправлены на сервер");
        setSelectedStudents([]);
        setGroupName("");
        setMenuActive(false);
        setBackgroundColor("#e8e8e8");
      } else {
        throw new Error("Ошибка при отправке данных на сервер");
      }
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          "https://www.infochemistryweb.ru/api/students",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");

          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchData();
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        if (Array.isArray(data.students)) {
          setStudents(data.students);
        } else {
          console.error(
            "Полученные данные не являются массивом студентов:",
            data
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, searchTerm]);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    setFilteredStudents(
      students.filter((student) =>
        `${student.first_name} ${student.middle_name || ""} ${
          student.last_name
        }`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, students]);

  const handleCreateGroup = () => {
    setPopUpActive(!isPopUpActive);
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  return (
    <div>
      <div className="acc_main_section">
        <div className="container">
          <div className="row">
            <div className="col-md-11">
              <h1 className="acc_header">Личный кабинет</h1>
            </div>
          </div>
          <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
            {" "}
            <Link to="/professor">
              <button className="acc_acc">Аккаунт</button>
            </Link>
            <Link to="/uspev_prof">
              <button className="acc_usp usp_lab">Лабораторные</button>
            </Link>
            <Link to="/mygroups">
              <button className="acc_groups">Мои группы</button>
            </Link>
            <Link to="/students_groups">
              <button className="acc_stud">Студенты</button>
            </Link>
          </ul>
          <div
            className={`hamburger_acc ${
              isMenuActive ? "hamburger_acc_active" : ""
            }`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="all_studs" style={{ backgroundColor }}>
          <div className="container">
            <h2 className="search_header">
              Список студентов{" "}
              <button className="djobir" onClick={handleCreateGroup}>
                Готово
              </button>
            </h2>{" "}
            <input
              className="search_labs"
              type="text"
              placeholder="Поиск студентов"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
            <div className="row">
              <div className="col-md-10">
                <ul className="ul_ulnik">
                  {loading ? (
                    <p>Загрузка...</p>
                  ) : (
                    filteredStudents
                      .sort((a, b) => {
                        const middleNameA = (a.middle_name || "")
                          .charAt(0)
                          .toLowerCase();
                        const middleNameB = (b.middle_name || "")
                          .charAt(0)
                          .toLowerCase();
                        if (middleNameA < middleNameB) return -1;
                        if (middleNameA > middleNameB) return 1;
                        return 0;
                      })
                      .map((student) => (
                        <li className="links_balling_balls" key={student.pk}>
                          <div className="student-item">
                            <input
                              type="checkbox"
                              id={`student-${student.pk}`}
                              checked={selectedStudents.includes(student.pk)}
                              onChange={() => handleCheckboxChange(student.pk)}
                            />
                            <label htmlFor={`student-${student.pk}`}>
                              {student.middle_name} {student.first_name}{" "}
                              {student.last_name}
                            </label>
                          </div>
                        </li>
                      ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`pop_up_group ${
            isPopUpActive ? "pop_up_group_active" : ""
          }`}
        >
          <h2 className="enter_group_name">Введите название группы</h2>
          <input
            className="group-name-input"
            type="text"
            placeholder="Введите название группы"
            value={groupName}
            onChange={handleGroupNameChange}
          />

          <Link to="/mygroups">
            <button className="save-group-button" onClick={handleSave}>
              Готово
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Grouping;
