import "./plan.css";

function Plan() {
  return (
    <section className="sixth_section">
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <h2 className="study_plan">Дополните ваш образовательный план </h2>
          </div>
          <div className="col-md-9">
            <h3 className="study_plan_sec">
              Используйте материалы нашего портала для ваших занятий со
              школьниками и студентами. Участвуйте в гражданской науке!
            </h3>
          </div>
          <div className="col-md-12">
            <img className="gifka" src="./img/гифка_с_колбой.gif" alt="..." />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Plan;
