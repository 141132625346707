import "./contacts.css";

function Contacts() {
  return (
    <section className="seventh_section">
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <h2 className="contacts_text">Наши контакты</h2>
          </div>
          <div className="col-md-10">
            <h3 className="spb">
              {" "}
              <img className="tick" src="./img/tick_contacts.png" alt="" />{" "}
              <span className="addressy">
                Санкт-Петербург, Ломоносова, д. 9, 191002
              </span>
            </h3>
          </div>
          <div className="col-md-10">
            <h3 className="mail">
              {" "}
              <img
                className="scorb_mail"
                src="./img/email_contacts.png"
                alt=""
              />{" "}
              <span className="maily"> skorb@itmo.ru</span>
            </h3>
          </div>
          <div className="col-md-10">
            <h3 className="phone_b">
              {" "}
              <img className="phone" src="./img/phone_contacts.png" alt="" />
              <span className="phone_nummy">+7 (999)-210-39-77</span>
            </h3>
          </div>
          <div className="col-md-12">
            <h4 className="copy">
              © 2023 ITMO University. All rights reserved
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
