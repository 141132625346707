import Appoint from "../appoint/appoint";
import HeaderAcc from "../navbaracc/navbaracc";

const AppointPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Appoint />
      </div>
    </div>
  );
};

export default AppointPage;
