import Header from "../navbar/navbar";
import Tbvideos from "../tbvideos/tbvideos";

const TbvideosPage = () => {
  return (
    <div className="header_acc">
      <Header />
      <div className="acc_page">
        <Tbvideos />
      </div>
    </div>
  );
};

export default TbvideosPage;
