import "./learn.css";
import { Link } from "react-router-dom";

function Learn() {
  return (
    <section className="fourth_section">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="learn_chem">Учитесь химии, занимаясь наукой!</h2>
          </div>

          <div className="col-md-4">
            <Link to="/login">
              <img
                className="first_card"
                src="img/first_card.png"
                alt="ERROR"
              />
            </Link>
            <h3 className="fourth_section_first_text_main">
              Лабораторные работы
            </h3>
            <h4 className="fourth_section_first_text_sec">
              Общая, неорганическая, физическая, органическая химия
            </h4>
          </div>

          <div className="col-md-4">
            <Link to="/login">
              <img className="sec_card" src="img/second_card.png" alt="ERROR" />
            </Link>
            <h3 className="fourth_section_sec_text_main">
              Химическое оборудование
            </h3>
            <h4 className="fourth_section_sec_text_sec">
              Установки для перегонки, неорг- и оргсинтеза
            </h4>
          </div>
          <div className="col-md-4">
            <Link to="/login">
              <img
                className="third_card"
                src="img/third_card.png"
                alt="ERROR"
              />
            </Link>
            <h3 className="fourth_section_third_text_main">
              Безопасность в лаборатории
            </h3>
            <h4 className="fourth_section_third_text_sec">
              Работа с опасными и реактивами и предотвращение ЧС
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Learn;
