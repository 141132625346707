import React, { useState } from "react";
import "./navbaracc.css";
import { Link } from "react-router-dom";

function HeaderAcc() {
  const [isMenuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };
  return (
    <header>
      <div className="container">
        <div className="row">
          <nav>
            <ul className={`menu_acc ${isMenuActive ? "menu_acc_active" : ""}`}>
              <div className="col-md-1">
                <li className="menu_item_acc">
                  <Link
                    to="https://infochemistry.ru/"
                    className="menu_link_acc"
                    target="_blank"
                  >
                    <img
                      className="logo_acc"
                      src={process.env.PUBLIC_URL + "/img/logo.png"}
                      alt="ERROR"
                    />
                  </Link>
                </li>
              </div>

              <div className="col-lg-2 col-md-1">
                <li className="menu_item_acc">
                  <Link to="/" className="menu_link_bolder_infochem_acc">
                    InfochemistryEdu
                  </Link>
                </li>
              </div>
              <div className="col-md-7">
                <div className="menu_light_acc">
                  <li className="menu_item_acc">
                    <Link to="/" className="menu_link_acc">
                      Главная
                    </Link>
                  </li>
                  <li className="menu_item_acc">
                    <Link to="/tbvideos" className="menu_link_acc">
                      Материалы⠀
                      <svg
                        className="arr_nav"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                      >
                        <path
                          d="M11 1.5L6.58926 5.91075C6.26382 6.23618 5.73618 6.23618 5.41074 5.91075L1 1.5"
                          stroke="#272D37"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </li>
                  <li className="menu_item_acc">
                    <Link
                      to="https://infochemistry.ru/"
                      className="menu_link_acc"
                      target="_blank"
                    >
                      НОЦ Инфохимии
                    </Link>
                  </li>
                  <li className="menu_item_acc">
                    <Link to="#" className="menu_link_acc">
                      ХимСофт
                    </Link>
                  </li>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="circle_thing">
                  <li className=" menu_item_acc">
                    <svg
                      className="bell"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                    >
                      <g opacity="0.8" clip-path="url(#clip0_613_2633)">
                        <path
                          d="M28.8976 25.2376C28.0404 24.4735 27.29 23.5975 26.6665 22.6332C25.9858 21.3021 25.5778 19.8485 25.4665 18.3576V13.9665C25.4724 11.6248 24.623 9.36161 23.0778 7.60206C21.5327 5.8425 19.3982 4.70774 17.0754 4.41097V3.2643C17.0754 2.94958 16.9504 2.64775 16.7278 2.4252C16.5053 2.20266 16.2034 2.07764 15.8887 2.07764C15.574 2.07764 15.2722 2.20266 15.0496 2.4252C14.8271 2.64775 14.7021 2.94958 14.7021 3.2643V4.42875C12.4001 4.74691 10.2914 5.88853 8.76652 7.64218C7.24168 9.39582 6.40403 11.6427 6.40872 13.9665V18.3576C6.29739 19.8485 5.88941 21.3021 5.20872 22.6332C4.59617 23.5953 3.85778 24.4712 3.01316 25.2376C2.91834 25.3209 2.84235 25.4235 2.79024 25.5384C2.73813 25.6534 2.7111 25.7781 2.71094 25.9043V27.1132C2.71094 27.3489 2.80459 27.575 2.97129 27.7417C3.13799 27.9084 3.36408 28.0021 3.59983 28.0021H28.3109C28.5467 28.0021 28.7728 27.9084 28.9395 27.7417C29.1062 27.575 29.1998 27.3489 29.1998 27.1132V25.9043C29.1997 25.7781 29.1726 25.6534 29.1205 25.5384C29.0684 25.4235 28.9924 25.3209 28.8976 25.2376ZM4.55983 26.2243C5.38686 25.4254 6.11503 24.5301 6.72872 23.5576C7.58615 21.9501 8.08643 20.1763 8.19538 18.3576V13.9665C8.16013 12.9248 8.33487 11.8866 8.7092 10.9138C9.08353 9.94098 9.64979 9.05344 10.3743 8.30404C11.0987 7.55463 11.9666 6.95867 12.9262 6.55166C13.8858 6.14464 14.9175 5.93489 15.9598 5.93489C17.0022 5.93489 18.0339 6.14464 18.9934 6.55166C19.953 6.95867 20.8209 7.55463 21.5454 8.30404C22.2699 9.05344 22.8361 9.94098 23.2105 10.9138C23.5848 11.8866 23.7595 12.9248 23.7243 13.9665V18.3576C23.8332 20.1763 24.3335 21.9501 25.1909 23.5576C25.8046 24.5301 26.5328 25.4254 27.3598 26.2243H4.55983Z"
                          fill="#342C41"
                        />
                        <path
                          d="M15.9994 30.971C16.5594 30.9581 17.0967 30.7476 17.5164 30.3767C17.9361 30.0058 18.2111 29.4984 18.2927 28.9443H13.6172C13.7012 29.5135 13.989 30.0328 14.4272 30.4056C14.8654 30.7784 15.4241 30.9793 15.9994 30.971Z"
                          fill="#342C41"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_613_2633">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg
                      className="line"
                      xmlns="http://www.w3.org/2000/svg"
                      width="2"
                      height="37"
                      viewBox="0 0 2 37"
                      fill="none"
                    >
                      <path
                        opacity="0.4"
                        d="M1 0.5L1 36.5"
                        stroke="#342C41"
                        stroke-linecap="round"
                      />
                    </svg>

                    <svg
                      className="circle_nav"
                      xmlns="http://www.w3.org/2000/svg"
                      width="51"
                      height="51"
                      viewBox="0 0 51 51"
                      fill="none"
                    >
                      <circle cx="25.5" cy="25.5" r="25.5" fill="#EBA14F" />
                    </svg>
                    <svg
                      className="arrow_nav"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M7.3169 9.79251L11.9632 4.91613C12.0407 4.83477 12.084 4.72667 12.084 4.61426C12.084 4.50185 12.0407 4.39375 11.9632 4.31239L11.9579 4.30713C11.9203 4.26755 11.875 4.23604 11.8249 4.2145C11.7747 4.19296 11.7207 4.18186 11.6661 4.18186C11.6115 4.18186 11.5575 4.19296 11.5073 4.2145C11.4571 4.23604 11.4119 4.26755 11.3743 4.30713L6.99928 8.89913L2.62603 4.30713C2.58842 4.26755 2.54316 4.23604 2.493 4.2145C2.44283 4.19296 2.38881 4.18186 2.33421 4.18186C2.27962 4.18186 2.2256 4.19296 2.17543 4.2145C2.12526 4.23604 2.08 4.26755 2.0424 4.30713L2.03715 4.31239C1.95959 4.39375 1.91632 4.50185 1.91632 4.61426C1.91632 4.72667 1.95959 4.83477 2.03715 4.91613L6.6834 9.79251C6.72426 9.8354 6.7734 9.86954 6.82785 9.89287C6.8823 9.91619 6.94092 9.92822 7.00015 9.92822C7.05939 9.92822 7.118 9.91619 7.17245 9.89287C7.2269 9.86954 7.27604 9.8354 7.3169 9.79251Z"
                        fill="#342C41"
                      />
                    </svg>
                  </li>
                </div>
              </div>
            </ul>

            <div className="infochem_again">
              <Link to="/">
                <h1 className="infochem_again_text">InfochemistryEdu</h1>
              </Link>
            </div>
            {/* <div className="login_button_2">Регистрация</div> */}
          </nav>
        </div>
      </div>
    </header>
  );
}

export default HeaderAcc;
