import Registration from "../registration/registration";

const RegistrationPage = () => {
  return (
    <div className="reg_page">
      <Registration />
    </div>
  );
};

export default RegistrationPage;
