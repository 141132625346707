import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./mylabs.css";
import { getAccessToken, refreshTokenIfNeeded } from "../auth";

const my_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M7.10678 12.7741C6.99283 12.7791 6.88052 12.7456 6.78786 12.6791L3.74107 10.1616C3.54477 9.98784 3.51522 9.69229 3.67321 9.48307C3.8467 9.28768 4.13997 9.25542 4.35178 9.40842L7.06607 11.6206L14.1911 5.03164C14.4018 4.87344 14.6984 4.90223 14.8747 5.09798C15.0511 5.29374 15.0489 5.59174 14.8696 5.78485L7.45286 12.6384C7.35838 12.7251 7.235 12.7735 7.10678 12.7741Z"
      fill="#BEBEBE"
      stroke="#BEBEBE"
      stroke-width="0.678571"
    />
  </svg>
);

const my_svg2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M7.10678 12.7741C6.99283 12.7791 6.88052 12.7456 6.78786 12.6791L3.74107 10.1616C3.54477 9.98784 3.51522 9.69229 3.67321 9.48307C3.8467 9.28768 4.13997 9.25542 4.35178 9.40842L7.06607 11.6206L14.1911 5.03164C14.4018 4.87344 14.6984 4.90223 14.8747 5.09798C15.0511 5.29374 15.0489 5.59174 14.8696 5.78485L7.45286 12.6384C7.35838 12.7251 7.235 12.7735 7.10678 12.7741Z"
      fill="white"
      stroke="white"
      stroke-width="0.678571"
    />
  </svg>
);

const my_svg3 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
  >
    <path
      d="M14.5818 27.8037C14.316 27.8153 14.0539 27.7372 13.8377 27.582L6.72851 21.7079C6.27049 21.3024 6.20152 20.6127 6.57018 20.1245C6.97499 19.6686 7.65928 19.5934 8.15351 19.9504L14.4868 25.112L31.1118 9.73788C31.6035 9.36876 32.2956 9.43592 32.7071 9.89269C33.1186 10.3495 33.1134 11.0448 32.6952 11.4954L15.3893 27.487C15.1689 27.6893 14.881 27.8022 14.5818 27.8037Z"
      fill="white"
      stroke="white"
      stroke-width="1.58333"
    />
  </svg>
);

function MyLabs() {
  const value1 = 0;
  const value2 = 1;
  const value3 = 2;
  const value4 = 3;

  // const [isProgressActive, setProgressActive] = useState(true);
  const [isMenuActive, setMenuActive] = useState(false);
  const [activeCorcleIndexes, setActiveCorcleIndexes] = useState([]);
  const [labs, setLabs] = useState([]);
  const [activeLabProgress, setActiveLabProgress] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [activeCorcles, setActiveCorcles] = useState({});

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const toggleLabProgress = (labIndex) => {
    setActiveLabProgress((prevProgress) => ({
      ...prevProgress,
      [labIndex]: !prevProgress[labIndex], // Инвертируем текущее состояние
    }));
  };

  const fetchStudentLabs = async (studentId) => {
    try {
      let token = getAccessToken();
      let labsResponse = await fetch(
        `https://www.infochemistryweb.ru/api/students/${studentId}/labs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (labsResponse.status === 401) {
        console.log("Токен устарел, пытаюсь обновить...");
        token = await refreshTokenIfNeeded(); // Обновляем токен
        labsResponse = await fetch(
          `https://www.infochemistryweb.ru/api/students/${studentId}/labs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (!labsResponse.ok) {
        throw new Error(`Ошибка: ${labsResponse.statusText}`);
      }

      const data = await labsResponse.json();
      setLabs(data.labs || []);
    } catch (error) {
      console.error(
        "Ошибка при получении данных о лабораторных работах студента:",
        error
      );
    }
  };

  // Используем PK студента для загрузки отчета

  const uploadFileToServer = async (labId) => {
    try {
      if (!file) {
        alert("Выберите файл для загрузки.");
        return;
      }
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("file", file);

      let token = getAccessToken();
      let response = await fetch(
        `https://www.infochemistryweb.ru/api/students/127/labs/${labId}/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded(); // Обновляем токен
        response = await fetch(
          `https://www.infochemistryweb.ru/api/students/127/labs/${labId}/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
      }

      if (!response.ok) {
        throw new Error("Ошибка при обновлении данных");
      }

      const data = await response.json();
      console.log("Файл успешно загружен:", data);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при загрузке файла на лабу:", error);
      setError("Ошибка загрузки файла. Пожалуйста, попробуйте снова.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const student_info = JSON.parse(localStorage.getItem("user_data")) || {};
    const studentId = student_info.pk;
    if (studentId) {
      fetchStudentLabs(studentId);
    } else {
      console.log("PK студента не найден");
    }
  }, []);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const toggleCorcle = (labId) => {
    setActiveCorcleIndexes((prevIndexes) => {
      const indexExists = prevIndexes.includes(labId);
      if (indexExists) {
        return prevIndexes.filter((i) => i !== labId);
      } else {
        return [...prevIndexes, labId];
      }
    });
  };

  const isCorcleActive = (labId) => {
    return activeCorcleIndexes.includes(labId);
  };

  const renderCorcle = (labId) => {
    return isCorcleActive(labId) ? my_svg : my_svg2;
  };

  const getMaxScore = (testsResults) => {
    if (!testsResults || testsResults.length === 0) {
      return " "; // Если массив пуст или отсутствует, вернуть пробел
    }
    const filteredScores = testsResults.filter((test) => test.score > 0);
    if (filteredScores.length === 0) {
      return " "; // Если после фильтрации результатов нет, вернуть пробел
    }
    const maxScore = Math.max(
      ...filteredScores.map((test) => (test.score / test.max_score) * 10)
    );
    return Math.round(maxScore);
  };

  const getCircleClass = (score) => {
    if (score === " ") {
      return "white";
    } else if (score === 0) {
      return "orange";
    } else if (score === 1) {
      return "orange";
    } else if (score === 2) {
      return "orange";
    } else if (score === 3) {
      return "orange";
    } else if (score === 4) {
      return "orange";
    } else if (score === 5) {
      return "dark-green";
    } else if (score === 6) {
      return "dark-green";
    } else if (score === 7) {
      return "dark-green";
    } else if (score === 8) {
      return "dark-green";
    } else if (score === 9) {
      return "dark-green";
    } else if (score === 10) {
      return "green";
    } else {
      return "white";
    }
  };

  const getLineColor = (color1, color2) => {
    console.log("FIRST " + color1);
    console.log("SEC " + color2);
    if (color1 === "dark-green" && color2 === "dark-green") {
      return "fir1";
    } else if (color1 === "orange" && color2 === "orange") {
      return "fir2";
    } else if (color1 === "green" && color2 === "green") {
      return "fir3";
    } else if (color1 === "white" && color2 === "white") {
      return "fir4";
    } else if (color1 === "white" && color2 === "orange") {
      return "fir6";
    } else if (color1 === "orange" && color2 === "white") {
      return "fir7";
    } else if (color1 === "white" && color2 === "dark-green") {
      return "fir8";
    } else if (color1 === "dark-green" && color2 === "white") {
      return "fir9";
    } else if (color1 === "white" && color2 === "green") {
      return "fir10";
    } else if (color1 === "green" && color2 === "white") {
      return "fir11";
    } else if (color1 === "orange" && color2 === "dark-green") {
      return "fir12";
    } else if (color1 === "dark-green" && color2 === "orange") {
      return "fir13";
    } else if (color1 === "orange" && color2 === "green") {
      return "fir14";
    } else if (color1 === "green" && color2 === "orange") {
      return "fir15";
    } else if (color1 === "dark-green" && color2 === "green") {
      return "fir16";
    } else if (color1 === "green" && color2 === "dark-green") {
      return "fir17";
    } else {
      return "default_color";
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="acc_header">Личный кабинет</h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/account">
          <button className="acc_acc_lab">Аккаунт</button>
        </Link>
        <Link to="/uspev">
          <button className="acc_usp">Успеваемость</button>
        </Link>
        <Link to="/mylabs">
          <button className="acc_lab_lab">Мои лабораторные</button>
        </Link>
        <Link to="/acctests">
          <button className="acc_test">Тесты</button>
        </Link>
        <Link to="/acclabs">
          <button className="acc_doc_lab_lab">Документация</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="mylabs">
        <h2 className="my_laboratonski">Мои лабораторные работы</h2>

        {labs.map((lab) => (
          <div className="lab-block" key={lab.laba.pk}>
            <h4 className="my_laboratonski_text">{lab.laba.name}</h4>

            <p
              className={`progress_ball ${
                activeLabProgress[lab.pk] ? "progress_ball_active" : ""
              }`}
              onClick={() => toggleLabProgress(lab.pk)}
            >
              Прогресс <span className="danba"></span>{" "}
              <span className="danba"></span>
            </p>
            <ul
              className={`elems_of_prog ${
                !activeLabProgress[lab.pk] ? "elems_of_prog_active" : ""
              }`}
            >
              {/* {lab.tests_results && lab.tests_results.length > 0 && ( */}
              <li className="corcle_item" key={`${lab.pk}-max-score`}>
                <span
                  className={`corcle ${
                    getMaxScore(lab.tests_results) !== " "
                      ? "corcle_active"
                      : ""
                  }`}
                >
                  {renderCorcle(lab.pk)}
                </span>
                {/* Тест: {getMaxScore(lab.tests_results)} */}
                Тест {getMaxScore(lab.tests_results)}
              </li>
              {/* )} */}
              <li className="corcle_item" key={`${lab.pk}-admission`}>
                <span
                  className /* "corcle" */={`corcle ${
                    lab.admission_score !== " " &&
                    lab.admission_score !== 0 &&
                    lab.admission_score !== null &&
                    lab.admission_score !== undefined
                      ? "corcle_active"
                      : ""
                  }`}
                >
                  {renderCorcle(lab.pk)}
                </span>
                Допуск{" "}
                {lab.admission_score !== 0 &&
                lab.admission_score !== " " &&
                lab.admission_score !== null &&
                lab.admission_score !== undefined
                  ? lab.admission_score
                  : ""}
              </li>
              <li className="corcle_item" key={`${lab.pk}-practice`}>
                <span
                  className /* "corcle" */={`corcle ${
                    lab.practice_score !== 0 &&
                    lab.practice_score !== " " &&
                    lab.practice_score !== null &&
                    lab.practice_score !== undefined
                      ? "corcle_active"
                      : ""
                  }`}
                >
                  {renderCorcle(lab.pk)}
                </span>
                Практика{" "}
                {lab.practice_score !== 0 &&
                lab.practice_score !== " " &&
                lab.practice_score !== null &&
                lab.practice_score !== undefined
                  ? lab.practice_score
                  : ""}
              </li>
              <li
                className="corcle_item"
                key={`${lab.pk}-report`}
                // onClick={() => toggleCorcle(lab.pk)}
              >
                <span
                  className /* "corcle" */={`corcle ${
                    lab.report_score !== 0 &&
                    lab.report_score !== " " &&
                    lab.report_score !== null &&
                    lab.report_score !== undefined
                      ? "corcle_active"
                      : ""
                  }`}
                >
                  {renderCorcle(lab.pk)}
                </span>
                Отчет {lab.report_score}
              </li>
              <div className="progress_bar_line">
                <span
                  className={`circle_svg_co ${getCircleClass(
                    getMaxScore(lab.tests_results)
                  )}`}
                >
                  <h1 className="f">
                    {value1 === -1 ? (
                      "1"
                    ) : (
                      <span className="my_svg_3_span">{my_svg3}</span>
                    )}
                  </h1>
                </span>
                <span
                  className={`line_betw_c ${getLineColor(
                    getCircleClass(getMaxScore(lab.tests_results)),
                    getCircleClass(lab.admission_score)
                  )}`}
                ></span>
                {console.log(lab.tests_results)}
                <span
                  className={`circle_svg_co ${getCircleClass(
                    lab.admission_score
                  )}`}
                >
                  {" "}
                  <h1 className="s">
                    {" "}
                    {value2 === -1 ? (
                      "2"
                    ) : (
                      <span className="my_svg_3_span">{my_svg3}</span>
                    )}
                  </h1>
                </span>
                <span
                  className={`line_betw_c ${getLineColor(
                    getCircleClass(lab.admission_score),
                    getCircleClass(lab.practice_score)
                  )}`}
                ></span>
                <span
                  className={`circle_svg_co ${getCircleClass(
                    lab.practice_score
                  )}`}
                >
                  {" "}
                  <h1 className="t">
                    {" "}
                    {value3 === -1 ? (
                      "3"
                    ) : (
                      <span className="my_svg_3_span">{my_svg3}</span>
                    )}
                  </h1>
                </span>
                <span
                  className={`line_betw_c ${getLineColor(
                    getCircleClass(lab.practice_score),
                    getCircleClass(lab.report_score)
                  )}`}
                ></span>
                <span
                  className={`circle_svg_co ${getCircleClass(
                    lab.report_score
                  )}`}
                >
                  {" "}
                  <h1 className="fr">
                    {" "}
                    {value4 === -1 ? (
                      "4"
                    ) : (
                      <span className="my_svg_3_span">{my_svg3}</span>
                    )}
                  </h1>
                </span>
              </div>
              <label className="file_input_label">
                <input
                  type="file"
                  className="file_input"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                Выбрать файл
              </label>
              <button
                className="ot4et_button"
                onClick={() => uploadFileToServer(lab.laba.pk)}
              >
                {loading ? "Загрузка..." : "Загрузить PDF"}
              </button>
              {error && <p className="error-message">{error}</p>}
            </ul>
            {/* Здесь ваш блок кода */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyLabs;
