import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./organic.css";

function Organic() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [labs, setLabs] = useState([]);
  const [originalLabs, setOriginalLabs] = useState([]); // Массив исходных лабораторных работ

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  // const filteredLabs = labs.filter((lab) =>
  //   lab.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  // );

  const sortLabsByName = (labs) => {
    return labs.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    async function fetchLabs() {
      try {
        const token = localStorage.getItem("access_token");

        const response = await fetch(
          "https://www.infochemistryweb.ru/api/labs?section_id=2",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchLabs();
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при обновлении данных");
        }
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        const fetchedLabs = data.labs.map(({ name, link }) => ({ name, link }));

        setLabs(fetchedLabs);
        setOriginalLabs(fetchedLabs);
      } catch (error) {
        console.error("Ошибка при загрузке лабораторных работ:", error);
      }
    }

    fetchLabs();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setLabs(originalLabs);
    } else {
      const filteredLabs = originalLabs.filter((lab) =>
        lab.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const sortedLabs = sortLabsByName(filteredLabs);
      setLabs(sortedLabs);
    }
  }, [searchTerm, originalLabs]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="acc_header">Личный кабинет</h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/account">
          <button className="acc_acc_lab">Аккаунт</button>
        </Link>
        <Link to="/uspev">
          <button className="acc_usp">Успеваемость</button>
        </Link>
        <Link to="/mylabs">
          <button className="acc_lab">Мои лабораторные</button>
        </Link>
        <Link to="/acctests">
          <button className="acc_test">Тесты</button>
        </Link>
        <Link to="/acclabs">
          <button className="acc_doc_lab">Документация</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="all_labs">
        <div className="container">
          <h2 className="search_header">
            Список лабораторных работ по неогранической химии
          </h2>
          <input
            className="search_labs"
            type="text"
            placeholder="Название лабораторной работы"
            value={searchTerm}
            onChange={handleSearch}
          ></input>
          {/* <button className="search_find">Найти</button> */}
          <div className="row">
            <div className="col-md-5">
              {labs.slice(0, Math.ceil(labs.length / 2)).map((lab) => (
                <div className="col-md-12 mb-10 custom-column" key={lab.name}>
                  <div className="just_a_gap">
                    <a
                      className="link_to_lab"
                      href={lab.link || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>{lab.name}</p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-5">
              {labs.slice(Math.ceil(labs.length / 2)).map((lab) => (
                <div className="col-md-12 mb-10 custom-column" key={lab.name}>
                  <div className="just_a_gap">
                    <a
                      className="link_to_lab"
                      href={lab.link || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>{lab.name}</p>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organic;
