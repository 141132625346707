import React from "react";
import { Link } from "react-router-dom";
import "./result.css";

function Result() {
  // Получение данных из localStorage на другой странице
  const maxScore = localStorage.getItem("max_score");
  const score = localStorage.getItem("score");
  //   const testPassed = localStorage.getItem("test_passed");

  let percent = Math.round((score / maxScore) * 100);

  //   let res;
  //   if (percent >= 90) {
  //     res = "Тест пройден!";
  //   } else {
  //     res = "Тест не пройден!";
  //   }

  return (
    <div>
      <div className="container">
        <div className="first_one">
          <div className="row">
            <div className="col-md-12">
              <h1 className="acc_header">Личный кабинет</h1>
            </div>
          </div>
          <ul className="acc_list">
            <Link to="/account">
              <button className="acc_acc">Аккаунт</button>
            </Link>
            <Link to="/uspev">
              <button className="acc_usp">Успеваемость</button>
            </Link>
            <Link to="/mylabs">
              <button className="acc_lab">Мои лабораторные</button>
            </Link>
            <Link to="/acctests">
              <button className="acc_test">Тесты</button>
            </Link>
            <Link to="/acclabs">
              <button className="acc_doc">Документация</button>
            </Link>
          </ul>

          <div className="test_passed_text">
            <h2 className="test_passed_f">Попытка завершена</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="179"
              height="179"
              viewBox="0 0 179 179"
              fill="none"
            >
              <path
                d="M49.9687 134.25L7.8291 92.1104L18.4572 81.6687L50.1552 113.367L60.5968 123.808L49.9687 134.25ZM92.1083 134.25L49.9687 92.1104L60.4104 81.4822L92.1083 113.18L160.725 44.5635L171.167 55.1916L92.1083 134.25ZM92.1083 92.1104L81.4802 81.6687L118.399 44.7499L129.027 55.1916L92.1083 92.1104Z"
                fill="#E94A5B"
              />
            </svg>
            <h4 className="test_passed_s">Ваш результат {percent}%</h4>
            <Link to={"/acctests"}>
              <button className="back_to_tests"> Вернуться к тестам</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
