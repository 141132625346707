import "./acclabs.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function Acclabs() {
  const [isMenuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };
  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="acc_header">Личный кабинет</h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/account">
            <button className="acc_acc_lab">Аккаунт</button>
          </Link>
          <Link to="/uspev">
            <button className="acc_usp">Успеваемость</button>
          </Link>
          <Link to="/mylabs">
            <button className="acc_lab">Мои лабораторные</button>
          </Link>
          <Link to="/acctests">
            <button className="acc_test">Тесты</button>
          </Link>
          <Link to="/acclabs">
            <button className="acc_doc_lab">Документация</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="tests_list">
          Список лабораторных работ
          <ul className="tests_links">
            <li className="test_link_ball">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_967_1936)">
                  <path
                    d="M14.7826 10.5983L10.3381 7.44254L8.97013 3.37695L9.81057 3.09416L9.52778 2.25373L4.48518 3.95046L4.76797 4.79089L5.6084 4.5081L6.97639 8.5737L5.34369 13.7743C5.24516 14.0879 5.23531 14.4226 5.31522 14.7414C5.39513 15.0603 5.56168 15.3508 5.79645 15.5808C6.03123 15.8108 6.32506 15.9714 6.64545 16.0448C6.96583 16.1182 7.30028 16.1015 7.61177 15.9966L14.3188 13.7398C14.6304 13.6351 14.9069 13.4462 15.1177 13.1941C15.3286 12.9419 15.4656 12.6364 15.5135 12.3112C15.5615 11.986 15.5186 11.6539 15.3895 11.3517C15.2604 11.0494 15.0502 10.7887 14.7822 10.5985L14.7826 10.5983ZM7.90873 8.56405L6.44883 4.22531L8.1297 3.65974L9.5896 7.99847L11.2325 9.16482L7.30512 10.4863L7.90873 8.56405ZM14.0365 12.8993L7.3294 15.156C7.17294 15.2085 7.00502 15.2167 6.84418 15.1798C6.68335 15.1428 6.53585 15.0622 6.41796 14.9467C6.30008 14.8312 6.21639 14.6854 6.17614 14.5253C6.1359 14.3653 6.14065 14.1972 6.18988 14.0397L6.97691 11.5323L12.1262 9.79967L14.2694 11.3211C14.4038 11.4169 14.5092 11.5478 14.5739 11.6997C14.6386 11.8515 14.66 12.0182 14.636 12.1815C14.6119 12.3447 14.5431 12.4982 14.4373 12.6248C14.3316 12.7515 14.1928 12.8465 14.0365 12.8993Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_967_1936">
                    <rect
                      width="14.1877"
                      height="14.1877"
                      fill="white"
                      transform="translate(0 4.52441) rotate(-18.597)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Link className="inor" to="/inorganic">
                <span className="labs_links">Неорганическая химия</span>
              </Link>
            </li>
            <li className="test_link_ball">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_967_1936)">
                  <path
                    d="M14.7826 10.5983L10.3381 7.44254L8.97013 3.37695L9.81057 3.09416L9.52778 2.25373L4.48518 3.95046L4.76797 4.79089L5.6084 4.5081L6.97639 8.5737L5.34369 13.7743C5.24516 14.0879 5.23531 14.4226 5.31522 14.7414C5.39513 15.0603 5.56168 15.3508 5.79645 15.5808C6.03123 15.8108 6.32506 15.9714 6.64545 16.0448C6.96583 16.1182 7.30028 16.1015 7.61177 15.9966L14.3188 13.7398C14.6304 13.6351 14.9069 13.4462 15.1177 13.1941C15.3286 12.9419 15.4656 12.6364 15.5135 12.3112C15.5615 11.986 15.5186 11.6539 15.3895 11.3517C15.2604 11.0494 15.0502 10.7887 14.7822 10.5985L14.7826 10.5983ZM7.90873 8.56405L6.44883 4.22531L8.1297 3.65974L9.5896 7.99847L11.2325 9.16482L7.30512 10.4863L7.90873 8.56405ZM14.0365 12.8993L7.3294 15.156C7.17294 15.2085 7.00502 15.2167 6.84418 15.1798C6.68335 15.1428 6.53585 15.0622 6.41796 14.9467C6.30008 14.8312 6.21639 14.6854 6.17614 14.5253C6.1359 14.3653 6.14065 14.1972 6.18988 14.0397L6.97691 11.5323L12.1262 9.79967L14.2694 11.3211C14.4038 11.4169 14.5092 11.5478 14.5739 11.6997C14.6386 11.8515 14.66 12.0182 14.636 12.1815C14.6119 12.3447 14.5431 12.4982 14.4373 12.6248C14.3316 12.7515 14.1928 12.8465 14.0365 12.8993Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_967_1936">
                    <rect
                      width="14.1877"
                      height="14.1877"
                      fill="white"
                      transform="translate(0 4.52441) rotate(-18.597)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Link className="inor" to="/organic">
                <span className="labs_links">Органическая химия</span>
              </Link>
            </li>
            <li className="test_link_ball">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_967_1936)">
                  <path
                    d="M14.7826 10.5983L10.3381 7.44254L8.97013 3.37695L9.81057 3.09416L9.52778 2.25373L4.48518 3.95046L4.76797 4.79089L5.6084 4.5081L6.97639 8.5737L5.34369 13.7743C5.24516 14.0879 5.23531 14.4226 5.31522 14.7414C5.39513 15.0603 5.56168 15.3508 5.79645 15.5808C6.03123 15.8108 6.32506 15.9714 6.64545 16.0448C6.96583 16.1182 7.30028 16.1015 7.61177 15.9966L14.3188 13.7398C14.6304 13.6351 14.9069 13.4462 15.1177 13.1941C15.3286 12.9419 15.4656 12.6364 15.5135 12.3112C15.5615 11.986 15.5186 11.6539 15.3895 11.3517C15.2604 11.0494 15.0502 10.7887 14.7822 10.5985L14.7826 10.5983ZM7.90873 8.56405L6.44883 4.22531L8.1297 3.65974L9.5896 7.99847L11.2325 9.16482L7.30512 10.4863L7.90873 8.56405ZM14.0365 12.8993L7.3294 15.156C7.17294 15.2085 7.00502 15.2167 6.84418 15.1798C6.68335 15.1428 6.53585 15.0622 6.41796 14.9467C6.30008 14.8312 6.21639 14.6854 6.17614 14.5253C6.1359 14.3653 6.14065 14.1972 6.18988 14.0397L6.97691 11.5323L12.1262 9.79967L14.2694 11.3211C14.4038 11.4169 14.5092 11.5478 14.5739 11.6997C14.6386 11.8515 14.66 12.0182 14.636 12.1815C14.6119 12.3447 14.5431 12.4982 14.4373 12.6248C14.3316 12.7515 14.1928 12.8465 14.0365 12.8993Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_967_1936">
                    <rect
                      width="14.1877"
                      height="14.1877"
                      fill="white"
                      transform="translate(0 4.52441) rotate(-18.597)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Link className="inor" to="/analytic">
                <span className="labs_links">Аналитическая химия</span>
              </Link>
            </li>
            <li className="test_link_ball">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_967_1936)">
                  <path
                    d="M14.7826 10.5983L10.3381 7.44254L8.97013 3.37695L9.81057 3.09416L9.52778 2.25373L4.48518 3.95046L4.76797 4.79089L5.6084 4.5081L6.97639 8.5737L5.34369 13.7743C5.24516 14.0879 5.23531 14.4226 5.31522 14.7414C5.39513 15.0603 5.56168 15.3508 5.79645 15.5808C6.03123 15.8108 6.32506 15.9714 6.64545 16.0448C6.96583 16.1182 7.30028 16.1015 7.61177 15.9966L14.3188 13.7398C14.6304 13.6351 14.9069 13.4462 15.1177 13.1941C15.3286 12.9419 15.4656 12.6364 15.5135 12.3112C15.5615 11.986 15.5186 11.6539 15.3895 11.3517C15.2604 11.0494 15.0502 10.7887 14.7822 10.5985L14.7826 10.5983ZM7.90873 8.56405L6.44883 4.22531L8.1297 3.65974L9.5896 7.99847L11.2325 9.16482L7.30512 10.4863L7.90873 8.56405ZM14.0365 12.8993L7.3294 15.156C7.17294 15.2085 7.00502 15.2167 6.84418 15.1798C6.68335 15.1428 6.53585 15.0622 6.41796 14.9467C6.30008 14.8312 6.21639 14.6854 6.17614 14.5253C6.1359 14.3653 6.14065 14.1972 6.18988 14.0397L6.97691 11.5323L12.1262 9.79967L14.2694 11.3211C14.4038 11.4169 14.5092 11.5478 14.5739 11.6997C14.6386 11.8515 14.66 12.0182 14.636 12.1815C14.6119 12.3447 14.5431 12.4982 14.4373 12.6248C14.3316 12.7515 14.1928 12.8465 14.0365 12.8993Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_967_1936">
                    <rect
                      width="14.1877"
                      height="14.1877"
                      fill="white"
                      transform="translate(0 4.52441) rotate(-18.597)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Link className="inor" to="/physic">
                <span className="labs_links">Физическая химия</span>
              </Link>
            </li>
          </ul>
        </div>
        <img
          className="colbs"
          src="../img/beaker chemistry-amico 1kolbs.svg"
          alt=""
        />
      </div>
    </div>
  );
}

export default Acclabs;
