import React from "react";
import "./test.css";
import { Link } from "react-router-dom";

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.checkQuestions = this.checkQuestions.bind(this);
    this.collectUserAnswers = this.collectUserAnswers.bind(this);
    this.state = {
      checked: false,
      userAnswers: {},
    };
  }

  collectUserAnswers() {
    const userAnswers = [];
    const data = this.props.data;

    data.questions.forEach((question) => {
      let selectedAnswers = this.state.userAnswers[question.title] || [];
      if (selectedAnswers.length === 0) {
        selectedAnswers = ["NULL"];
      }
      userAnswers.push(selectedAnswers);
    });

    console.log(JSON.stringify(userAnswers));
  }

  updateUserAnswers = (questionTitle, selectedAnswer) => {
    this.setState((prevState) => ({
      userAnswers: {
        ...prevState.userAnswers,
        [questionTitle]: prevState.userAnswers[questionTitle]
          ? [...prevState.userAnswers[questionTitle], selectedAnswer]
          : [selectedAnswer],
      },
    }));
  };

  sendDataToServer = async () => {
    try {
      const userAnswers = this.state.userAnswers;
      const token = localStorage.getItem("access_token");
      // console.log("Структура теста:", JSON.stringify(this.props.data));
      const dataToSend = {
        pk_test: 1,
        answers: Object.values(userAnswers),
      };

      const response = await fetch(
        "https://www.infochemistryweb.ru/api/test_answer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          // Если получена ошибка 401 (Unauthorized), обновляем токен
          await this.handleUnauthorizedErrorAndResendRequest();
          return; // Прерываем выполнение, так как запрос был повторен
        }
        throw new Error("Ошибка при отправке данных на сервер");
      }

      const data = await response.json();
      localStorage.setItem("max_score", data.max_score);
      localStorage.setItem("score", data.score);
      localStorage.setItem("pk_test", data.pk_test);

      window.location.href = "/result";
    } catch (error) {
      console.error("Ошибка:", error);
      // Обработка других ошибок при отправке данных на сервер
    }
  };

  handleUnauthorizedErrorAndResendRequest = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      const refreshResponse = await fetch(
        "https://www.infochemistryweb.ru/api/auth/token/refresh",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshToken}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!refreshResponse.ok) {
        throw new Error("Ошибка обновления токена");
      }
      const { access_token: newAccessToken } = await refreshResponse.json();
      // Обновить токены в локальном хранилище
      localStorage.setItem("access_token", newAccessToken);

      // Повторить отправку запроса с новым access token
      await this.sendDataToServer();
    } catch (refreshError) {
      console.error("Ошибка обновления токена:", refreshError);
      // Обработка ошибок обновления токена
      // Например, перенаправление на страницу авторизации или другие действия
    }
  };

  checkQuestions(ev) {
    ev.preventDefault();
    this.collectUserAnswers();
    this.sendDataToServer();
  }

  render() {
    let data = this.props.data;
    let questions = data.questions;
    let j = 0;

    questions = questions.map((el, index) => {
      let counter = 0;
      el.variants.forEach((e) => {
        if (e.flag) counter++;
      });

      let variants = el.variants.map((e, i) => {
        let type = "radio";

        j++;
        if (counter >= 2) {
          type = "checkbox";
        }
        if (e.text) {
          type = "text";
        }

        return (
          <div className="row_test">
            <input
              className="input_test"
              type={type}
              name={type === "checkbox" ? "id" + j : "id" + index}
              id={"id" + j}
              onChange={(event) => this.updateUserAnswers(el.title, i + 1)}
            />
            <span className="answer_balls">
              <label for={"id" + j}>{e.title} </label>
            </span>
          </div>
        );
      });
      return (
        <div>
          <h3 className="test_title">{el.title}</h3>
          {el.code && (
            <img
              className="test_image_tb"
              src={el.code}
              style={{ width: "150px" }}
              alt="Ваше изображение"
            />
          )}
          <div className="variants">{variants}</div>
        </div>
      );
    });
    return (
      <div className="container">
        <div className="first_one">
          <div className="row">
            <div className="col-md-12">
              <h1 className="acc_header">Личный кабинет</h1>
            </div>
          </div>
          <ul className="acc_list">
            <Link to="/account">
              <button className="acc_acc">Аккаунт</button>
            </Link>
            <Link to="/uspev">
              <button className="acc_usp">Успеваемость</button>
            </Link>{" "}
            <Link to="/mylabs">
              <button className="acc_lab">Мои лабораторные</button>
            </Link>
            <Link to="/acctests">
              <button className="acc_test">Тесты</button>
            </Link>
            <Link to="/acclabs">
              <button className="acc_doc">Документация</button>
            </Link>
          </ul>
        </div>
        <div className="test_section">
          <h2 className="test_name">
            {" "}
            {data.testName} {this.state.checked ? "(проверен)" : ""}
          </h2>
          {questions}

          <input
            className="test_button_tb"
            type="button"
            disabled={this.state.checked ? true : false}
            value="Сохранить"
            onClick={this.checkQuestions}
          />
        </div>
      </div>
    );
  }
}

export default Test;
