import React, { useState } from "react";
import "./pdf_upload.css";
import { Link } from "react-router-dom";
import { refreshTokenIfNeeded, getAccessToken } from "../auth";

function PDFUploader() {
  // const [newSectionName, setNewSectionName] = useState(""); // Добавлено состояние для нового имени секции

  // const createNewSection = async () => {
  //   try {
  //     const token = localStorage.getItem("access_token");
  //     const requestBody = { name: newSectionName };

  //     const response = await fetch(
  //       "http://www.infochemistryweb.ru/api/sections_labs/new",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`Ошибка: ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     console.log("Новая секция успешно создана:", data);
  //     // Обработка успешного создания новой секции
  //   } catch (error) {
  //     console.error("Ошибка при создании новой секции:", error);
  //     // Обработка ошибок
  //   }
  // };

  const [file, setFile] = useState(null);
  const [test, setTest] = useState({
    name: "",
    laba_id: "",
    attempts: "",
    questions: [],
  });
  const [fileName, setFileName] = useState(""); // Состояние для хранения имени файла
  const [isMenuActive, setMenuActive] = useState(false);
  const [sectionId, setSectionId] = useState(null); // Состояние для хранения выбранного section_id
  const [loading, setLoading] = useState(false); // Состояние для отслеживания загрузки файла

  const handleSectionIdChange = (e) => {
    setSectionId(e.target.value);
  };
  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const uploadFileName = async (fileName, sectionId) => {
    try {
      let token = getAccessToken();
      const requestBody = { name: fileName, section_id: sectionId };

      console.log("Отправляемые данные при запросе:", requestBody);

      let response = await fetch(
        "https://www.infochemistryweb.ru/api/labs/new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        response = await fetch("https://www.infochemistryweb.ru/api/labs/new", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Данные об ошибке:", errorData);
        throw new Error(`Ошибка: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Название файла успешно отправлено:", data);
      return data.pk;
    } catch (error) {
      console.error("Ошибка при отправке названия файла:", error);
      throw error;
    }
  };

  const uploadFileToLab = async (file, labId) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      let token = getAccessToken();
      let response = await fetch(
        `https://www.infochemistryweb.ru/api/labs/${labId}/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        response = await fetch(
          `https://www.infochemistryweb.ru/api/labs/${labId}/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Данные об ошибке:", errorData);
        throw new Error(`Ошибка: ${response.statusText}`);
      }

      const data = await response.json();
      alert("Файл успешно загружен:", data);
    } catch (error) {
      console.error("Ошибка при загрузке файла на лабу:", error);
      alert("Ошибка при загрузке файла на лабу");
    }
  };
  // const deleteAllLabs = async () => {
  //   try {
  //     const token = localStorage.getItem("access_token");

  //     if (!token) {
  //       console.error("Токен авторизации отсутствует");
  //       return;
  //     }

  //     const response = await fetch("http://www.infochemistryweb.ru/api/labs", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.status === 401) {
  //       const refreshToken = localStorage.getItem("refresh_token");
  //       const refreshResponse = await fetch(
  //         "http://www.infochemistryweb.ru/api/auth/token/refresh",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${refreshToken}`,
  //           },
  //         }
  //       );

  //       if (refreshResponse.ok) {
  //         const { access_token } = await refreshResponse.json();
  //         localStorage.setItem("access_token", access_token);
  //         deleteAllLabs(); // Повторная попытка отправить запрос с обновленным токеном
  //       } else {
  //         throw new Error("Ошибка при обновлении токена");
  //       }
  //     } else if (!response.ok) {
  //       throw new Error("Ошибка при обновлении данных");
  //     }

  //     if (!response.ok) {
  //       throw new Error(`Ошибка: ${response.statusText}`);
  //     }

  //     const responseData = await response.json();
  //     const labsData = responseData.labs;
  //     console.log("Формат данных о лабораторных работах:", labsData);

  //     if (Array.isArray(labsData)) {
  //       for (const lab of labsData) {
  //         const labId = lab.pk;
  //         const deleteResponse = await fetch(
  //           `http://www.infochemistryweb.ru/api/labs/${labId}`,
  //           {
  //             method: "DELETE",
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         if (response.status === 401) {
  //           const refreshToken = localStorage.getItem("refresh_token");
  //           const refreshResponse = await fetch(
  //             "http://www.infochemistryweb.ru/api/auth/token/refresh",
  //             {
  //               method: "POST",
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${refreshToken}`,
  //               },
  //             }
  //           );

  //           if (refreshResponse.ok) {
  //             const { access_token } = await refreshResponse.json();
  //             localStorage.setItem("access_token", access_token);
  //             deleteResponse(); // Повторная попытка отправить запрос с обновленным токеном
  //           } else {
  //             throw new Error("Ошибка при обновлении токена");
  //           }
  //         } else if (!response.ok) {
  //           throw new Error("Ошибка при обновлении данных");
  //         }

  //         if (!response.ok) {
  //           throw new Error(`Ошибка: ${response.statusText}`);
  //         }

  //         if (!deleteResponse.ok) {
  //           console.error(`Ошибка при удалении лабы с id ${labId}`);
  //         } else {
  //           console.log(`Лаба с id ${labId} успешно удалена`);
  //         }
  //       }
  //     } else {
  //       console.error("Неправильный формат данных для списка лаб");
  //     }
  //   } catch (error) {
  //     console.error("Ошибка при удалении лаб:", error);
  //     // Обработка ошибок
  //   }
  // };
  const handleUpload = async () => {
    try {
      if (!file) {
        alert("Выберите файл для загрузки.");
        return;
      }
      setLoading(true);
      const labId = await uploadFileName(fileName, sectionId);
      await uploadFileToLab(file, labId);
      setTest({ ...test, laba_id: labId });

      setLoading(false);
    } catch (error) {
      alert.error("Ошибка при загрузке файла:", error);

      // Обработка ошибок
    }
  };

  const addQuestion = () => {
    setTest({
      ...test,
      questions: [
        ...test.questions,
        {
          question: "",
          answers: [{ answer: "" }],
        },
      ],
    });
  };

  const handleQuestionChange = (e, questionIndex) => {
    const updatedQuestions = test.questions.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, question: e.target.value };
      }
      return question;
    });

    setTest({
      ...test,
      questions: updatedQuestions,
    });
  };

  const handleFlagChange = (e, questionIndex, answerIndex) => {
    const updatedQuestions = [...test.questions];
    const updatedAnswers = [...updatedQuestions[questionIndex].answers];
    updatedAnswers[answerIndex] = {
      ...updatedAnswers[answerIndex],
      flag: e.target.checked,
    };
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      answers: updatedAnswers,
    };

    setTest({
      ...test,
      questions: updatedQuestions,
    });
  };
  const addAnswer = (questionIndex) => {
    const updatedQuestions = [...test.questions];
    const newAnswer = { answer: "" };
    updatedQuestions[questionIndex].answers.push(newAnswer);

    setTest({
      ...test,
      questions: updatedQuestions,
    });
  };

  const handleAnswerChange = (e, questionIndex, answerIndex) => {
    const updatedQuestions = [...test.questions];
    updatedQuestions[questionIndex].answers[answerIndex].answer =
      e.target.value;

    setTest({
      ...test,
      questions: updatedQuestions,
    });
  };

  const saveTest = async (test) => {
    try {
      let token = getAccessToken();

      const transformedTest = {
        name: test.name,
        laba_id: test.laba_id,
        attempts: test.attempts,
        questions: test.questions.map((question) => ({
          question: question.question,
          answers: question.answers.map((answer) => ({
            answer: answer.answer,
            flag: answer.flag || false,
          })),
        })),
      };

      console.log("Отправляемый тест на сервер:", transformedTest);

      let response = await fetch(
        "https://www.infochemistryweb.ru/api/tests/new",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(transformedTest),
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        response = await fetch(
          "https://www.infochemistryweb.ru/api/tests/new",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(transformedTest),
          }
        );
      }

      if (!response.ok) {
        throw new Error("Ошибка при отправке данных");
      }

      const data = await response.json();
      alert("Тест успешно загружен");
      console.log("Ответ от сервера:", data);
    } catch (error) {
      console.error("Ошибка при отправке теста:", error);
      alert("Ошибка при отправке теста");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <h1 className="acc_header">Личный кабинет</h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/professor">
          <button className="acc_acc usp_lab_acc">Аккаунт</button>
        </Link>
        <Link to="/pdf_upload">
          <button className="acc_usp usp_lab_dab">Лабораторные</button>
        </Link>
        <Link to="/mygroups">
          <button className="acc_groups">Мои группы</button>
        </Link>
        <Link to="/students_groups">
          <button className="acc_stud">Студенты</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="PDFS">
        <div className="instr_menu">
          <span className="instruct">
            Краткая инструкция по созданию лабораторной работы
          </span>
          <span className="helping">
            <br></br>
            1)Выберите файл
            <br></br>
            2)Напишите название лабораторной
            <br></br>
            3)Выберете одну из секций
            <br></br>
            4)Нажмите кнопку "Загрузить PDF"
            <br></br>
            <br></br>
            <span className="instruct">
              После этого, чтобы загрузить тест к этой лабораторной работе
            </span>
            <br></br>
            1)Выберете количество попыток
            <br></br>
            2)Напишите название теста
            <br></br>
            3)Нажмите "Добавить вопрос"
            <br></br>
            4)После этого добавляйте ответы и делайте новые вопросы
            <br></br>
            5)Нажмите кнопку "Сохранить тест"
            <br></br>
            <br></br>
          </span>
        </div>
        <div className="pdf_uploading_balls">
          <input
            type="file"
            className="choose_your_file"
            onChange={handleFileChange}
          />

          <div className="radio_buttons_for_sections">
            <input
              type="text"
              className="input_for_test_name"
              placeholder="Название лабораторной работы"
              value={fileName}
              onChange={handleFileNameChange}
            />
            <label>
              <input
                type="radio"
                value="1"
                checked={sectionId === "1"}
                onChange={handleSectionIdChange}
              />
              Неорганическая химия
            </label>
            <label>
              <input
                type="radio"
                value="2"
                checked={sectionId === "2"}
                onChange={handleSectionIdChange}
              />
              Органическая химия
            </label>
            <label>
              <input
                type="radio"
                value="3"
                checked={sectionId === "3"}
                onChange={handleSectionIdChange}
              />
              Аналитическая химия
            </label>
            <label>
              <input
                type="radio"
                value="4"
                checked={sectionId === "4"}
                onChange={handleSectionIdChange}
              />
              Физическая химия
            </label>
          </div>
        </div>
        <button className="upload_your_pdf" onClick={handleUpload}>
          {loading ? "Загрузка..." : "Загрузить PDF"}
        </button>
        <div className="test-builder">
          <h5 className="щщщ">Попытки</h5>
          <input
            className="input_for_test_name"
            type="text"
            placeholder="Количество попыток"
            value={test.testName}
            onChange={(e) => setTest({ ...test, attempts: e.target.value })}
          />

          <h2 className="balling_with_test">Создание теста</h2>
          <input
            className="input_for_test_name"
            type="text"
            placeholder="Название теста"
            value={test.testName}
            onChange={(e) => setTest({ ...test, name: e.target.value })}
          />

          {test.questions.map((question, questionIndex) => (
            <div key={questionIndex}>
              <input
                className="input_for_test_name"
                type="text"
                placeholder="Вопрос"
                value={question.question}
                onChange={(e) => handleQuestionChange(e, questionIndex)}
              />

              {question.answers.map((answer, answerIndex) => (
                <div key={answerIndex}>
                  <input
                    className="input_for_test_name"
                    type="text"
                    placeholder="Ответ"
                    value={answer.answer}
                    onChange={(e) =>
                      handleAnswerChange(e, questionIndex, answerIndex)
                    }
                  />
                  <label>
                    <input
                      className="set_da_flag"
                      type="checkbox"
                      checked={answer.flag || false}
                      onChange={(e) =>
                        handleFlagChange(e, questionIndex, answerIndex)
                      }
                    />
                    Правильный ответ
                  </label>
                </div>
              ))}

              <button
                className="upload_your_pdf upload_your_pdf_otv"
                onClick={() => addAnswer(questionIndex)}
              >
                Добавить ответ
              </button>
            </div>
          ))}

          <button className="upload_your_pdf" onClick={addQuestion}>
            Добавить вопрос
          </button>
          <button
            className="upload_your_pdf upload_your_pdf_save"
            onClick={saveTest}
          >
            Сохранить тест
          </button>
        </div>
      </div>
      {/* <div className="create-section">
        <input
          type="text"
          value={newSectionName}
          onChange={(e) => setNewSectionName(e.target.value)}
          placeholder="Введите название секции"
        />
        <button onClick={createNewSection}>Добавить новую секцию</button>
      </div> */}

      {/* <button onClick={deleteAllLabs}>УДАЛИТЬ ВСЕ ЛАБЫ</button> */}
    </div>
  );
}

export default PDFUploader;
