import { Link } from "react-router-dom";
import "./sci.css"; // Убедитесь, что путь правильный

function Sci() {
  return (
    <section className="second_section">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="second_section_main_text">Химия — наука «ручная»</h2>
            <h3 className="second_section_third_text">
              Центр инфохимии Университета ИТМО поможет студентам,
              преподавателям и всем интересующимся освоить практическую химию.
              Изучите видеоуроки по технике безопасности и
              <span className="yellow_span">
                {" "}
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="yellow_span"
                >
                  приемам работы
                </Link>
              </span>
              в лаборатории, выполняйте
              <span className="yellow_span">
                {" "}
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="yellow_span"
                >
                  лабораторные работы
                </Link>
              </span>
              , проходите
              <span className="yellow_span">
                {" "}
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="yellow_span"
                >
                  тесты
                </Link>
              </span>
              , используйте
              <span className="yellow_span">
                {" "}
                <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="yellow_span"
                >
                  химические приложения
                </Link>
              </span>{" "}
              и многое другое.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sci;
