import StudentsGroups from "../students_groups/student_groups";
import HeaderAcc from "../navbaracc/navbaracc";

const StudentsGroupsPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <StudentsGroups />
      </div>
    </div>
  );
};

export default StudentsGroupsPage;
