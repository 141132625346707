import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./appoint.css";

function Appoint() {
  const { groupId } = useParams();

  const [labs, setLabs] = useState([]);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [isMenuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          "https://www.infochemistryweb.ru/api/labs",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchData(); // Повторная попытка отправить запрос с обновленным токеном
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при обновлении данных");
        }

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }

        const data = await response.json();
        setLabs(data.labs);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (event, labPk) => {
    if (event.target.checked) {
      setSelectedLabs([...selectedLabs, labPk]);
    } else {
      const filteredLabs = selectedLabs.filter((pk) => pk !== labPk);
      setSelectedLabs(filteredLabs);
    }
  };

  const handlePatchRequest = async () => {
    try {
      const token = localStorage.getItem("access_token");

      const response = await fetch(
        `https://www.infochemistryweb.ru/api/groups/${groupId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            labs: [
              ...selectedLabs,
              ...labs
                .filter((lab) => !selectedLabs.includes(lab.pk))
                .map((lab) => lab.pk),
            ],
          }),
        }
      );
      if (response.status === 401) {
        const refreshToken = localStorage.getItem("refresh_token");
        const refreshResponse = await fetch(
          "https://www.infochemistryweb.ru/api/auth/token/refresh",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        if (refreshResponse.ok) {
          const { access_token } = await refreshResponse.json();
          localStorage.setItem("access_token", access_token);
          handlePatchRequest(); // Повторная попытка отправить запрос с обновленным токеном
        } else {
          throw new Error("Ошибка при обновлении токена");
        }
      } else if (!response.ok) {
        throw new Error("Ошибка при обновлении данных");
      }

      if (!response.ok) {
        throw new Error(`Ошибка: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Данные успешно отправлены:", data);
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <h1 className="acc_header">Личный кабинет </h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/professor">
          <button className="acc_acc usp_lab_acc">Аккаунт</button>
        </Link>
        <Link to="/pdf_upload">
          <button className="acc_usp usp_lab_dab">Лабораторные</button>
        </Link>
        <Link to="/mygroups">
          <button className="acc_groups">Мои группы</button>
        </Link>
        <Link to="/students_groups">
          <button className="acc_stud">Студенты</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="AAAAA">
        {labs &&
          labs.map((lab) => (
            <div key={lab.pk}>
              <label>
                <input
                  type="checkbox"
                  onChange={(event) => handleCheckboxChange(event, lab.pk)}
                />
                <span className="AAAAAAAAAAAAAAAAAAAAA"> {lab.name}</span>
              </label>
            </div>
          ))}

        <button onClick={handlePatchRequest} className="submit-button">
          Назначить лабы
        </button>
      </div>
    </div>
  );
}

export default Appoint;
