import "./main.css";
import { Link } from "react-router-dom";

function Main() {
  return (
    <section className="main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 ">
            <h1 className="main_text">
              Практикум Центра Инфохимии Университета ИТМО
            </h1>
            <h2 className="sec_text">
              Начните свой профессиональный путь в химии
            </h2>
            <Link to="/registration" className="join_link_button">
              <button className="join_button">
                <span className="join_button_span">Присоединяйся</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <img className="ITMO" src="img/ITMO.png" alt="ERROR" />
    </section>
  );
}

export default Main;
