import React from "react";
import "./tbvideos.css";

function Tbvideos() {
  return (
    <div className="tbvideos">
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239539?list=ln-AFHwAon2zRRi6wxokk"
        target="_blank"
        rel="noreferrer"
      >
        Бумажная хроматография
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239540?list=ln-YgZrRWMnELvwEOdazv"
        target="_blank"
        rel="noreferrer"
      >
        Mеханохимия
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239541?list=ln-U5dM4esRKW9TYFJe6V"
        target="_blank"
        rel="noreferrer"
      >
        Осушение растворителей сульфатом натрия
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239543?list=ln-C1xfgJS6QAFTcUj2r5"
        target="_blank"
        rel="noreferrer"
      >
        Очистка вещества возгонкой
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239544?list=ln-E6DruP5h3WPfXCz5Gm"
        target="_blank"
        rel="noreferrer"
      >
        Очистка от примесей перекристаллизацией
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239545?list=ln-ir3oFNogZT6WBbF3NE"
        target="_blank"
        rel="noreferrer"
      >
        Отгонка с горячим паром
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239546?list=ln-3fdItQaKd3EA8MClJ4"
        target="_blank"
        rel="noreferrer"
      >
        Перегонка с дефлегматором
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239547?list=ln-lqGD0YQTgBWOQNa252"
        target="_blank"
        rel="noreferrer"
      >
        Перегонка под вакуумом с капилляром
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239548%2Fln-njICjwzn7xHStUlxAA%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Синтез в трехгорлой колбе
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239549%2Fln-e3xuNnEiJr1CpqQqJ1%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Синтез с поглощением токсичных газов
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239550%2Fln-H7W2aJYgKjKxltdVh8%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Тонкослойная хроматография
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239551%2Fln-GeNUjwJReHSToZdP4n%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Экстракция в делительной воронке
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239553%2Fln-GbDVUR0ccmusZsFJ1S%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Работа на ВЭЖХ
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239554%2Fln-uRXEVMOXlC6Fz944ia%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Фильтрование через воронку Бюхнера
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239555%2Fln-u0YB1kXTVGHY5qyKBU%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Cгибание фильтра
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239556?list=ln-p1HfSLpboe8irOpEBU"
        target="_blank"
        rel="noreferrer"
      >
        Роторный испаритель
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239557?list=ln-z1kYZAgVZFQzfbDkQF"
        target="_blank"
        rel="noreferrer"
      >
        Фильтрация на обычной воронке
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239558?list=ln-73BdzH1nVeky40tgnK"
        target="_blank"
        rel="noreferrer"
      >
        Работа с экстрактором Сокслета
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video-188764969_456239559?list=ln-1NUoYq2YwDTdeLilZ0"
        target="_blank"
        rel="noreferrer"
      >
        Эксикаторы
      </a>
      <br />
      <h1> Техника Безопаности</h1>
      <br />

      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239582%2Fln-71sFq2j40hZPzP7p9v%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Начало работы в лаборатории
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239582%2Fln-71sFq2j40hZPzP7p9v%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Средства индивидуальной защиты
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239583%2Fln-BZRn8RYdTPtbRKYard%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Общие правила поведения в лаборатории
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239584%2Fln-m1UMdE35DMEiOJ4ebU%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Пожарная безопасность
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239585%2Fln-lmoAVYCgNzXnsZymlv%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Работа с реактивами и посудой
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239586%2Fln-8p7sF9NbWCbPdV6MCd%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Приём пищи в лаборатории
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239587%2Fln-CjXRz5JOTyCZZFVxYL%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Устранение разливов реактивов
      </a>
      <a
        className="tbvideo_link"
        href="https://vk.com/video/@infochemistry?z=video-188764969_456239589%2Fln-dgHNSo4shp25yFxent%2Fpl_-188764969_-2"
        target="_blank"
        rel="noreferrer"
      >
        Работа с электроприборами
      </a>
      <a
        className="tbvideo_link"
        href="https://www.youtube.com/playlist?list=PLK9aQ4zNEjOr3ViS_sl-q8gi1ujU1YTU8"
        target="_blank"
        rel="noreferrer"
      >
        Оборудование и посуда химической лаборатории (плейлист)
      </a>
      <br></br>
      <br></br>
    </div>
  );
}

export default Tbvideos;
