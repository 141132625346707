import HeaderAcc from "../navbaracc/navbaracc";
import Acctest from "../acctests/acctests";

const TestsPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Acctest />
      </div>
    </div>
  );
};

export default TestsPage;
