import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./bachelors1.css";

function StudentsListParsed() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const { id } = useParams();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          "https://www.infochemistryweb.ru/api/students",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchData();
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        if (Array.isArray(data.students)) {
          setStudents(data.students);
        } else {
          console.error(
            "Полученные данные не являются массивом студентов:",
            data
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, searchTerm]);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    setFilteredStudents(
      students.filter((student) =>
        `${student.first_name} ${student.middle_name || ""} ${
          student.last_name
        }`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, students]);

  const sortStudentsByLastName = (students) => {
    return students.sort((a, b) => {
      const lastNameA = a.middle_name.toLowerCase();
      const lastNameB = b.middle_name.toLowerCase();

      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });
  };

  const sortedStudents = sortStudentsByLastName(filteredStudents);

  return (
    <div>
      <div className="acc_main_section">
        <div className="container">
          <div className="row">
            <div className="col-md-11">
              <h1 className="acc_header">Личный кабинет</h1>
            </div>
          </div>
          <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
            {" "}
            <Link to="/professor">
              <button className="acc_acc usp_lab_acc">Аккаунт</button>
            </Link>
            <Link to="/pdf_upload">
              <button className="acc_usp usp_lab">Лабораторные</button>
            </Link>
            <Link to="/mygroups">
              <button className="acc_groups">Мои группы</button>
            </Link>
            <Link to="/students_groups">
              <button className="acc_stud acc_stud_balling">Студенты</button>
            </Link>
          </ul>
          <div
            className={`hamburger_acc ${
              isMenuActive ? "hamburger_acc_active" : ""
            }`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="all_studs">
          <div className="container">
            <h2 className="search_header">
              <span className="stud_list_for_grrrr">Список студентов </span>
              <Link to="/grouping">
                <button className="djobir">Создать группу</button>
              </Link>
            </h2>

            <input
              className="search_labs"
              type="text"
              placeholder="Поиск студентов"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
            <div className="row">
              <div className="col-md-10">
                {loading ? (
                  <p>Загрузка...</p>
                ) : (
                  <ul className="ul_ulnik">
                    {filteredStudents
                      .slice(0, Math.ceil(sortedStudents.length))
                      .map((student) => (
                        <li className="links_balling_balls" key={student.pk}>
                          <Link to={`/bachelors1/${student.pk}`}>
                            {student.middle_name} {student.first_name}{" "}
                            {student.last_name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsListParsed;
