import HeaderAcc from "../navbaracc/navbaracc";
import TestComponent from "../testComponent/testComponent";

const TestComponentPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <TestComponent />
      </div>
    </div>
  );
};

export default TestComponentPage;
