import HeaderAcc from "../navbaracc/navbaracc";
import Test from "../test/test";
import data from "../test/data";

const TestPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Test data={data} />
      </div>
    </div>
  );
};

export default TestPage;
