import Professor from "../professor/professor";
import HeaderAcc from "../navbaracc/navbaracc";

const ProfessorPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="prof_page">
        <Professor />
      </div>
    </div>
  );
};

export default ProfessorPage;
