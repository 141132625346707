// Получение токенов из localStorage
export const getAccessToken = () => localStorage.getItem("access_token");

export const getRefreshToken = () => localStorage.getItem("refresh_token");

// Сохранение токенов в localStorage
export const setAccessToken = (accessToken) => {
  localStorage.setItem("access_token", accessToken);
};

// Функция для обновления access токена
export const refreshTokenIfNeeded = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw new Error("Refresh token is missing");
  }

  try {
    const response = await fetch(
      "https://www.infochemistryweb.ru/api/auth/token/refresh",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
        body: JSON.stringify({ refresh: refreshToken }), // если требуется тело запроса
      }
    );

    if (response.ok) {
      const { access_token } = await response.json();
      setAccessToken(access_token);
      return access_token;
    } else if (response.status === 401) {
      throw new Error("Refresh token is invalid or expired");
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};
