import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Состояние для отслеживания ошибки

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://www.infochemistryweb.ru/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Неверная почта или пароль");
      }

      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("user_data", JSON.stringify(data.user));

      if (data.user.is_admin) {
        navigate("/professor");
      } else {
        navigate("/account");
      }
    } catch (error) {
      setError(error.message); // Устанавливаем сообщение об ошибке
    }
  };

  return (
    <section className="eight_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <h1 className="infochemedu">
              <Link to="/" className="infochemedu">
                InfochemistryEdu
              </Link>
            </h1>
            <h2 className="create_acc">ВОЙДИТЕ В АККАУНТ</h2>

            <div className="action_btns_reg">
              <Link to="/registration" className="action_btn1_log">
                Регистрация
              </Link>
              <Link to="/login" className="action_btn2_log">
                Вход
              </Link>
            </div>
            <form onSubmit={handleSubmit} className="form">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-md-6 offset-md-3 col-sm-10 offset-sm-1  col-xl-6 offset-xl-3  ">
                  <div className="column">
                    <div className="input-box">
                      <div className="input-box-login">
                        <input
                          type="email"
                          name="email"
                          placeholder="Почта"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-md-6 offset-md-3 col-sm-10 offset-sm-1  col-xl-6 offset-xl-3  ">
                  <div className="column2">
                    <div className="input-box">
                      <div className="input-box-login">
                        <div className="input-box-login-pswd">
                          {" "}
                          <input
                            type="password"
                            name="password"
                            placeholder="Пароль"
                            required
                            value={formData.password}
                            onChange={handleChange}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit">Войти</button>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
          <div className="col-md-6">
            <img className="reg_image" src="./img/login.png" alt="" />
          </div>
        </div>
        <div className="login_small">
          <Link to="/registration">
            <span className="login_small_text">Регистрация</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Login;
