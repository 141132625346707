const data = {
  // testName: "Техника безопасности",
  questions: [
    {
      title:
        "Какому наказанию должен быть подвергнут учащийся, грубо или систематически нарушающий технику безопасности?",
      variants: [
        {
          title: "Отстранение от работ до пересдачи техники безопасности",
          flag: true,
        },
        { title: "Устное замечание от преподавателя" },
        { title: "Исключение из вуза за неуспеваемость" },
        { title: "Денежный штраф" },
        { title: "Снижение оценки за практикум" },
      ],
    },
    {
      title: "Что НЕ следует делать с защитными перчатками?",
      variants: [
        { title: "Убирать в карман после использования", flag: true },
        { title: "Снимать при выходе из лаборатории" },
        { title: "Помыть руки после их ношения" },
        {
          title: "Открывать дверь в лабораторию только руками в них",
          flag: true,
        },
        { title: "Трогать ими лицо", flag: true },
      ],
    },
    {
      title:
        "Что необходимо сделать для допуска к лабораторной работе, кроме сдачи Т/Б?",
      variants: [
        {
          title: "Ознакомиться с описанием работы и подготовить лаб.журнал",
          flag: true,
        },
        {
          title:
            "Изучить свойства и опасности веществ, с которыми предстоит работать",
          flag: true,
        },
        {
          title: "Изучить связанную теорию",
          flag: true,
        },
        { title: "Закрыть все двери" },
        { title: "Надеть бахилы" },
      ],
    },
    {
      title: "Какие вещества в лаборатории ядовиты?",
      variants: [
        { title: "Соли тяжелых металлов" },
        { title: "Органические гетероциклы" },
        { title: "Растворители" },
        { title: "Кислоты и щелочи" },
        { title: "Все в той или иной степени", flag: true },
      ],
    },
    {
      title:
        "Почему нельзя допускать попадание органических растворителей на кожу?",
      variants: [
        { title: "Они могут самовоспламениться" },
        { title: "Они растворят кожу" },
        { title: "Они проникают сквозь кожу", flag: true },
        { title: "Они дорогие" },
        { title: "Они пачкаются" },
      ],
    },
    {
      title: "Определите знак опасности (картинки)",
      code: "https://www.infochemistryweb.ru/test_img/first_img_test.png",
      variants: [
        { title: "Окисляющее вещество" },
        { title: "Вызывает коррозию" },
        { title: "Газ под давлением", flag: true },
        { title: "Взрывчатое вещество" },
        { title: "Представляет опасность для окружающей среды" },
      ],
    },
    {
      title: "Определите знак опасности (картинки 2)",
      code: "https://www.infochemistryweb.ru/test_img/sec_img_test.png",
      variants: [
        { title: "Окисляющее вещество", flag: true },
        { title: "Вызывает коррозию" },
        { title: "Газ под давлением" },
        { title: "Взрывчатое вещество" },
        { title: "Легковоспламеняющееся вещество" },
      ],
    },
    {
      title: "Определите знак опасности (картинки 3)",
      code: "https://www.infochemistryweb.ru/test_img/third_img_test.png",
      variants: [
        { title: "Смертельно опасное вещество" },
        { title: "Представляет опасность для окружающей среды" },
        { title: "Причиняет вред здоровью" },
        { title: "Поражает отдельные органы", flag: true },
        { title: "Легковоспламеняющееся вещество" },
      ],
    },
    {
      title: "Определите знак опасности (картинки 4)",
      code: "https://www.infochemistryweb.ru/test_img/four_img_test.png",
      variants: [
        { title: "Поражает отдельные органы" },
        { title: "Газ под давлением" },
        {
          title: "Представляет опасность для окружающей среды",
          flag: true,
        },
        { title: "Смертельно опасное вещество" },
        { title: "Причиняет вред здоровью" },
      ],
    },
    {
      title: "Определите знак опасности (картинки 5)",
      code: "https://www.infochemistryweb.ru/test_img/five_img_test.png",
      variants: [
        { title: "Окисляющее вещество" },
        { title: "Причиняет вред здоровью" },
        { title: "Легковоспламеняющееся вещество", flag: true },
        { title: "Взрывчатое вещество" },
        { title: "Вызывает коррозию" },
      ],
    },
    {
      title:
        "Почему нельзя работать в одиночку и/или в отсутсвии преподавателя?",
      variants: [
        { title: "Это не соответсвует уставу" },
        { title: "Одному человеку нельзя доверять" },
        { title: "Это затрудняет оказание помощи в случае ЧС", flag: true },
        { title: "Чтобы развивать нетворкинг" },
        { title: "Чтобы эффективнее использовать ресурсы лаборатории" },
      ],
    },
    {
      title:
        "Почему в лаборатории нельзя носить шорты, короткие юбки и открытую обувь?",
      variants: [
        { title: "Это неэстетично" },
        { title: "Это неприлично" },
        { title: "Потому что в лаборатории холодно" },
        {
          title: "Потому что более вероятно, что вещества попадут на кожу",
          flag: true,
        },
        {
          title: "Потому что более вероятно поражение электрическим током",
        },
      ],
    },
    {
      title: "Что можно сделать с длинными волосами в лаборатории?",
      variants: [
        { title: "Обязательно состричь" },
        { title: "Завязать резинкой", flag: true },
        { title: "Использовать шапочку или платок", flag: true },
        { title: "Спрятать под халат" },
        { title: "Оставить как есть" },
      ],
    },
    {
      title: "Чем принципиально отличается химический лабораторный халат?",
      variants: [
        { title: "Длина до колен или ниже", flag: true },
        { title: "Белый цвет" },
        { title: "Пуговицы спереди", flag: true },
        { title: "Ткань хлопок или смесовый", flag: true },
        { title: "Отсутствие карманов" },
      ],
    },
    {
      title: "В каких случаях надо надевать защитные очки?",
      variants: [
        { title: "При пересыпании едких веществ", flag: true },
        { title: "При работе с вакуумом", flag: true },
        { title: "При нагревании на плитке", flag: true },
        { title: "При поливке цветов в лаборатории" },
        { title: "При переливании концентрированных кислот", flag: true },
      ],
    },
    {
      title: "Что нужно сделать, если необходимо залезть по пояс в вытяжку?",
      variants: [
        { title: "Выключить в ней все приборы", flag: true },
        { title: "Убрать из нее все реактивы", flag: true },
        { title: "Выключить в ней свет" },
        { title: "Убрать из нее посуду", flag: true },
        { title: "Надеть противогаз" },
      ],
    },
    {
      title: "Что можно из этого делать в лаборатории?",
      variants: [
        { title: "Есть" },
        { title: "Курить" },
        { title: "Жевать жвачку" },
        { title: "Принимать лекарства" },
        { title: "Ничего из вышеперечисленного", flag: true },
      ],
    },
    {
      title:
        "Почему нельзя использовать для питья хорошо помытый лабораторный стакан?",
      variants: [
        { title: "Так не принято" },
        { title: "Он неудобный" },
        { title: "Он острый" },
        { title: "На нем могут быть адсорбированные вещества", flag: true },
        { title: "Он дорогой" },
      ],
    },
    {
      title: "Почему нельзя оставлять сумки в проходе?",
      variants: [
        { title: "Они помешают эвакуации при ЧС", flag: true },
        { title: "О них можно споткнуться", flag: true },
        { title: "На них могут пролить реактивы", flag: true },
        { title: "Это неэстетично" },
        { title: "Так их легко потерять" },
      ],
    },
    {
      title: "Как правильно включить электроприбор с сетевым шнуром?",
      variants: [
        { title: "Шнур в сеть, кнопка вкл, шнур к прибору" },
        { title: "Шнур к прибору, шнур в сеть, кнопка вкл.", flag: true },
        { title: "Кнопка вкл, шнур к прибору, шнур в сеть" },
        { title: "В любой последовательности" },
        { title: "Только при выключенном общем рубильнике" },
      ],
    },
    {
      title: "Как правильно выключить электроприбор с сетевым шнуром?",
      variants: [
        { title: "Шнур из сети, кнопка выкл, шнур от прибора" },
        { title: "Шнур от прибора, шнур из сети, кнопка выкл." },
        { title: "Кнопка выкл, шнур из сети, шнур от прибора", flag: true },
        { title: "В любой последовательности" },
        { title: "Только при выключенном общем рубильнике" },
      ],
    },
    {
      title:
        "Что необходимо сделать, если при работе ощущается запах перегретого ПВХ?",
      variants: [
        { title: "Понизить температуру на приборе" },
        { title: "Отключить рубильник", flag: true },
        { title: "Засыпать прибор песком" },
        { title: "Включить вытяжку сильнее" },
        { title: "Залить прибор водой" },
      ],
    },
    {
      title: "Что самое важное при спасении человека, которого бьет током?",
      variants: [
        { title: "Быстро обесточить источник поражения", flag: true },
        { title: "Дать пострадавшему воды" },
        {
          title:
            "Самому не попасть под напряжение (использовать непроводящие материалы)",
          flag: true,
        },
        { title: "Открыть окна" },
        { title: "Найти аммиак" },
      ],
    },
    {
      title: "Когда следует делать массаж сердца и искусственное дыхание?",
      variants: [
        { title: "Всегда" },
        {
          title: "Только если пульс прерывистый и отсутствует",
          flag: true,
        },
        { title: "Только если есть необходимые знания", flag: true },
        { title: "Если пострадавший в шоке" },
        { title: "Если других людей в лаборатории нет" },
      ],
    },
    {
      title: "Что следует сделать перед работой с ЛВЖ?",
      variants: [
        { title: "Потушить все спиртовки и горелки", flag: true },
        { title: "Убрать раскаленные нагревательные приборы", flag: true },
        { title: "Поставить на стол миску с песком" },
        { title: "Надеть противогаз" },
        { title: "Приготовить 2%-ю лимонную кислоту" },
      ],
    },
    {
      title: "Что следует сделать первым делом при возгорании?",
      variants: [
        { title: "Приступить к тушению" },
        { title: "Сообщить преподавателю/ментору", flag: true },
        { title: "Выключить общий рубильник" },
        { title: "Открыть дверь и окно" },
        { title: "Заплакать" },
      ],
    },
    {
      title: "Что нужно сделать, если пламя небольшое в вытяжном шкафу?",
      variants: [
        { title: "Сфотографировать" },
        { title: "Убрать горючие вещества из вытяжки", flag: true },
        { title: "Выключить вытяжку", flag: true },
        { title: "Обесточить электроприборы", flag: true },
        { title: "Эвакуироваться" },
      ],
    },
    {
      title: "Что можно тушить водой?",
      variants: [
        { title: "Электроплитку" },
        { title: "Гексан" },
        { title: "Бумагу", flag: true },
        { title: "Магний" },
        { title: "Кусочки пластика", flag: true },
      ],
    },
    {
      title: "Чем можно тушить горящий бензин (30 мл)?",
      variants: [
        { title: "Холодной водой" },
        { title: "Теплой водой" },
        { title: "Песком", flag: true },
        { title: "Мокрым халатом", flag: true },
        { title: "Порошковым огнетушителем", flag: true },
      ],
    },
    {
      title: "В какой последовательности следует использовать огнетушитель?",
      code: "",
      variants: [
        { title: "Нажать на рычаг, вырвать чеку, направить на пламя" },
        { title: "Вырвать чеку, нажать на рычаг, направить на пламя" },
        { title: "Направить на пламя, нажать на рычаг, вырвать чеку" },
        {
          title: "Вырвать чеку, направить на пламя, нажать на рычаг",
          flag: true,
        },
        { title: "Нажать на рычаг, направить на пламя, вырвать чеку" },
      ],
    },
    {
      title:
        "Сколько времени выдает пену/порошок лабораторный 6 литровый огнетушитель?",
      variants: [
        { title: "0,5-1 секунду" },
        { title: "5-10 секунд", flag: true },
        { title: "50-100 секунд" },
        { title: "5-10 минут" },
        { title: "до 50 минут" },
      ],
    },
    {
      title: "Почему нельзя путать крышки у реактивов?",
      variants: [
        { title: "Это неэстетично" },
        { title: "Они не подойдут идеально" },
        {
          title: "Можно испортить реактив попадаем частиц другого",
          flag: true,
        },
        { title: "Это не так - крышки можно свободно менять" },
        { title: "Потому что новые крышки стоят дорого" },
      ],
    },
    {
      title: "Почему нельзя хранить вещества в мерной посуде более суток?",
      variants: [
        { title: "Пробка негерметична и может 'закиснуть'", flag: true },
        { title: "Слишком широкое дно" },
        { title: "Слишком большой объем" },
        {
          title:
            "Мерная колба может изменить объем/испортиться при выпадении нерастворимого осадка",
          flag: true,
        },
        {
          title: "Мерная посуда более ценная и может кому-то понадобиться",
          flag: true,
        },
      ],
    },
    {
      title: "Что надо обязательно написать на банке с новым раствором?",
      variants: [
        { title: "Название реактива и концентрацию", flag: true },
        { title: "Структурную формулу" },
        {
          title: "Дату приготовления раствора и срок годности",
          flag: true,
        },
        { title: "Номер гранта, по которому куплен реактив" },
        { title: "Цель приготовления реактива" },
      ],
    },
    {
      title: "Чем можно отобрать реактив из банки?",
      variants: [
        { title: "Шпателем", flag: true },
        { title: "Дозатором", flag: true },
        { title: "Пипеткой", flag: true },
        { title: "Пинцетом", flag: true },
        { title: "Рукой" },
      ],
    },
    {
      title: "Как готовить водный раствор серной кислоты?",
      variants: [
        { title: "Приливать воду к веществу" },
        { title: "Приливать вещество к воде", flag: true },
        { title: "Приливать воду и вещество одновременно в стакан" },
        { title: "Это делать нельзя" },
        { title: "Можно делать как удобно" },
      ],
    },
    {
      title: "Как готовить водный раствор ацетона?",
      variants: [
        { title: "Приливать воду к веществу" },
        { title: "Приливать вещество к воде" },
        { title: "Приливать воду и вещество одновременно в стакан" },
        { title: "Это делать нельзя" },
        { title: "Можно делать как удобно", flag: true },
      ],
    },
    {
      title: "Как готовить раствор NaOH?",
      variants: [
        { title: "Приливать воду к веществу" },
        { title: "Присыпать вещество к воде", flag: true },
        { title: "Смешивать воду и вещество одновременно в стакане" },
        { title: "Это делать нельзя" },
        { title: "Можно делать как удобно" },
      ],
    },
    {
      title: "Как готовить водный раствор металлической ртути?",
      variants: [
        { title: "Приливать воду к веществу" },
        { title: "Приливать вещество к воде" },
        { title: "Приливать воду и вещество одновременно в стакан" },
        { title: "Это делать нельзя", flag: true },
        { title: "Можно делать как удобно" },
      ],
    },
    {
      title: "При прочих равных, какой лабораторный стакан более термостойкий?",
      variants: [
        {
          title: "Из специального тонкого термостойкого стекла ",
          flag: true,
        },
        { title: "Более толстого стекла" },
        { title: "С клеймом термостойкости", flag: true },
        { title: "Полипропиленовый" },
        { title: "Они все одинаковые" },
      ],
    },
    {
      title:
        "Почему при разбивании посуды рекомендуется сразу глубоко вдохнуть и выдохнуть?",
      variants: [
        { title: "Азы химической йоги" },
        { title: "Потому что научный руководитель будет ругаться" },
        {
          title: "Чтобы рефлекторно не схватить осколки голыми руками",
          flag: true,
        },
        { title: "Чтобы морально подготовиться к уборке" },
        { title: "Чтобы крикнуть 'на счастье' громче" },
      ],
    },
    {
      title:
        "Куда можно вылить (при разрешении от преподавателя) грязный гексан?",
      variants: [
        { title: "В раковину, разбавив водой" },
        { title: "В раковину в вытяжке" },
        { title: "В оргслив", flag: true },
        { title: "В слив тяжелых металлов" },
        { title: "К цветок" },
      ],
    },
    {
      title:
        "Куда можно вылить (при разрешении от преподавателя) отработанную 0,1 М серную кислоту?",
      variants: [
        { title: "В раковину, разбавив водой", flag: true },
        { title: "В раковину в вытяжке", flag: true },
        { title: "В оргслив" },
        { title: "В слив тяжелых металлов" },
        { title: "К цветок" },
      ],
    },
    {
      title:
        "Куда можно вылить (при разрешении от преподавателя) раствор нитрата кадмия?",
      variants: [
        { title: "В раковину, разбавив водой" },
        { title: "В раковину в вытяжке" },
        { title: "В оргслив" },
        { title: "В слив тяжелых металлов", flag: true },
        { title: "К цветок" },
      ],
    },
    {
      title: "Почему следует начать уборку за 10 минут до конца работы?",
      variants: [
        { title: "Это необязательно" },
        { title: "Чтобы успеть прибраться", flag: true },
        { title: "Потому что так принято" },
        { title: "Это хорошая примета" },
        { title: "Чтобы успеть доделать эксперимент" },
      ],
    },
    {
      title: "Что сделать первым делом, если разлит 10% NaCl?",
      variants: [
        { title: "Засыпать песком" },
        { title: "Засыпать опилками" },
        { title: "Протереть мокрой тряпкой", flag: true },
        { title: "Эвакуироваться" },
        { title: "Ничего, само высохнет" },
      ],
    },
    {
      title: "Что сделать первым делом, если разлит бром?",
      variants: [
        { title: "Засыпать песком", flag: true },
        { title: "Засыпать опилками" },
        { title: "Протереть мокрой тряпкой" },
        { title: "Эвакуироваться" },
        { title: "Ничего, само высохнет" },
      ],
    },
    {
      title: "Что сделать первым делом, если разлит ацетон?",
      variants: [
        { title: "Засыпать песком" },
        { title: "Убрать источники огня", flag: true },
        { title: "Протереть мокрой тряпкой" },
        { title: "Эвакуироваться" },
        { title: "Ничего, само высохнет" },
      ],
    },
    {
      title: "Чем можно собрать ртуть?",
      variants: [
        { title: "Медью", flag: true },
        { title: "Руками" },
        { title: "Деревянной палочкой" },
        { title: "Пылесосом" },
        { title: "Оставить так, сама испарится" },
      ],
    },
    {
      title: "Как следует обрабатывать ожог концентрированной серной кислотой?",
      variants: [
        { title: "2% содой, большим кол-вом воды, затем тканью" },
        {
          title: "Убрать мягкой тканью, большим кол-вом воды, 2% содой",
          flag: true,
        },
        {
          title:
            "Убрать мягкой тканью, большим кол-вом воды, 2% лимонной к-той",
        },
        { title: "Большим кол-вом воды, мягкой таканью, 2% содой" },
        {
          title: "Большим кол-вом воды, 2% лимонной кислотой, мягкой тканью",
        },
      ],
    },
    {
      title: "Что делать при ожоге глаза щелочью?",
      variants: [
        { title: "Промыть 2% раствором лимонной кислоты, затем водой" },
        {
          title: "Промыть большим кол-вом воды, обратиться ко врачу",
          flag: true,
        },
        { title: "Обратиться ко врачу ничем не промывая" },
        {
          title:
            "Промыть большим кол-вом воды, затем 2% лимонной кислотой, затем ко врачу",
        },
        {
          title:
            "Промыть 2% раствором лимонной кислоты, затем водой, затем ко врачу",
        },
      ],
    },
  ],
};

export default data;
