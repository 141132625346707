import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { refreshTokenIfNeeded, getAccessToken } from "../auth"; // Импортируем нужные функции
import "./testsResults.css";

function TestsResults() {
  const { pk } = useParams();
  const [testResults, setTestResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const findBestAttempt = (tests) => {
    if (tests.length === 0) return null;

    let bestAttempt = tests[0]; // Предполагаем, что первая попытка самая лучшая

    for (let i = 1; i < tests.length; i++) {
      if (tests[i].result.score > bestAttempt.result.score) {
        bestAttempt = tests[i]; // Если находим более высокий балл, сохраняем эту попытку как лучшую
      }
    }

    return bestAttempt;
  };

  useEffect(() => {
    const fetchStudentTests = async () => {
      try {
        let token = getAccessToken();
        let response = await fetch(
          `https://www.infochemistryweb.ru/api/students/${pk}/tests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          // Если токен просрочен
          // Обновляем токены и повторяем запрос
          token = await refreshTokenIfNeeded();
          if (token) {
            response = await fetch(
              `https://www.infochemistryweb.ru/api/students/${pk}/tests`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        }

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }

        const data = await response.json();
        setTestResults(data.tests); // Сохраняем результаты тестов
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setLoading(false);
      }
    };

    fetchStudentTests();
  }, [pk]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  const determineTestPassed = (test) => {
    const percent = (test.result.score / test.result.max_score) * 100;
    return percent >= 90 ? "Да" : "Нет";
  };

  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-11">
            <h1 className="acc_header">Личный кабинет</h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/professor">
            <button className="acc_acc">Аккаунт</button>
          </Link>
          <Link to="/pdf_upload">
            <button className="acc_usp usp_lab">Лабораторные</button>
          </Link>
          <Link to="/mygroups">
            <button className="acc_groups">Мои группы</button>
          </Link>
          <Link to="/students_groups">
            <button className="acc_stud">Студенты</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="all_info">
          {findBestAttempt(testResults) && (
            <div className="test_result">
              <h2 className="billings">
                Максимальный балл:{" "}
                {findBestAttempt(testResults).result.max_score}
                <br></br>
                Балл: {findBestAttempt(testResults).result.score}
                <br></br>
                Тест пройден:{" "}
                {determineTestPassed(findBestAttempt(testResults))}
                <br></br>
                Процент выполнения:{" "}
                {Math.round(
                  (findBestAttempt(testResults).result.score /
                    findBestAttempt(testResults).result.max_score) *
                    100
                )}
                %<br></br>
                <Link to={`/bachelors1/${pk}`}>
                  <button className="test_results_button_yeah">Назад</button>
                </Link>
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestsResults;
