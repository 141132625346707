import "./path.css";
import { Link } from "react-router-dom";

function Path() {
  return (
    <section className="fifth_section">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2 className="sci_path">Как начать свой путь в науке?</h2>
          </div>
          <div className="col-md-5">
            <img className="reg_portal" src="./img/reg_portal.png" alt="" />
          </div>
          <div className="col-md-5 offset-md-1 col-sm-5 offset-sm-0">
            <img className="learn_tech" src="./img/earn_tech.png" alt="" />
          </div>
          <div className="col-md-5">
            <img className="pass_test" src="./img/tests.png" alt="" />
          </div>
          <div className="col-md-5 offset-md-1 col-sm-5 offset-sm-0">
            <img className="labs_res" src="./img/labs_res.png" alt="" />
          </div>
          <div className="col-md-8 offset-md-0 col-sm-6 offset-sm-4">
            <Link to="/registration">
              <button class="learn_more">Узнать больше</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Path;
