import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./mygroups.css";

const kolba_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_1396_209)">
      <path
        d="M14.7836 10.5991L10.3391 7.44328L8.97111 3.37768L9.81154 3.09489L9.52875 2.25446L4.48616 3.95119L4.76894 4.79162L5.60938 4.50883L6.97737 8.57443L5.34467 13.7751C5.24614 14.0886 5.23629 14.4233 5.31619 14.7422C5.3961 15.061 5.56266 15.3515 5.79743 15.5815C6.0322 15.8116 6.32604 15.9722 6.64642 16.0455C6.96681 16.1189 7.30125 16.1023 7.61275 15.9974L14.3198 13.7406C14.6314 13.6358 14.9079 13.4469 15.1187 13.1948C15.3296 12.9426 15.4666 12.6371 15.5145 12.3119C15.5625 11.9868 15.5195 11.6547 15.3905 11.3524C15.2614 11.0501 15.0512 10.7894 14.7832 10.5992L14.7836 10.5991ZM7.90971 8.56478L6.44981 4.22605L8.13068 3.66047L9.59057 7.9992L11.2334 9.16555L7.30609 10.487L7.90971 8.56478ZM14.0375 12.9L7.33038 15.1568C7.17391 15.2092 7.006 15.2175 6.84516 15.1805C6.68432 15.1436 6.53682 15.0629 6.41894 14.9474C6.30106 14.8319 6.21737 14.6861 6.17712 14.5261C6.13687 14.366 6.14163 14.198 6.19086 14.0405L6.97789 11.533L12.1272 9.8004L14.2704 11.3219C14.4048 11.4176 14.5102 11.5486 14.5749 11.7004C14.6395 11.8522 14.661 12.019 14.6369 12.1822C14.6128 12.3455 14.5441 12.4989 14.4383 12.6256C14.3325 12.7522 14.1938 12.8472 14.0375 12.9Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1396_209">
        <rect
          width="14.1877"
          height="14.1877"
          fill="white"
          transform="translate(0 4.52466) rotate(-18.597)"
        />
      </clipPath>
    </defs>
  </svg>
);

function MyGroups() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          "https://www.infochemistryweb.ru/api/groups",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchGroups(); // Повторно пытаемся получить данные со студентами
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        if (response.ok) {
          const data = await response.json();
          console.log(data.groups);
          setGroups(data.groups); // Устанавливаем полученные группы в состояние
        } else {
          throw new Error("Ошибка при получении списка групп");
        }
      } catch (error) {
        console.error("Ошибка при получении списка групп:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-11">
            <h1 className="acc_header">Личный кабинет</h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/professor">
            <button className="acc_acc usp_lab_acc">Аккаунт</button>
          </Link>
          <Link to="/pdf_upload">
            <button className="acc_usp usp_lab">Лабораторные</button>
          </Link>
          <Link to="/mygroups">
            <button className="acc_groups my_grrr">Мои группы</button>
          </Link>
          <Link to="/students_groups">
            <button className="acc_stud">Студенты</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="groups_list_balls">
          <h2 className="list_of_my_balls">Список моих групп</h2>

          {loading ? (
            <p>Загрузка...</p>
          ) : (
            <ul className="all_the_groups_av">
              {groups.map((group) => (
                <li className="group_name_ff" key={group.id}>
                  {/* Добавляем Link для перехода */}
                  <Link
                    to={`/mygroups/${group.pk}`}
                    className="group_link"
                    style={{ textDecoration: "none" }}
                  >
                    {kolba_svg} {group.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>{" "}
    </div>
  );
}

export default MyGroups;
