import PDFUploader from "../pdf_upload/pdf_upload";
import HeaderAcc from "../navbaracc/navbaracc";

const PDFUploaderPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="prof_page">
        <PDFUploader />
      </div>
    </div>
  );
};

export default PDFUploaderPage;
