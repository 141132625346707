import HeaderAcc from "../navbaracc/navbaracc";
import Analytic from "../analytic/analytic";

const AnalyticPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Analytic />
      </div>
    </div>
  );
};

export default AnalyticPage;
