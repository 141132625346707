import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./registration.css";

function Registration() {
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Состояние для отслеживания ошибки

  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    isu_number: "",
    last_name: "",
    middle_name: "",
    password1: "",
    password2: "",
    phone_number: "",
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://www.infochemistryweb.ru/api/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Такой аккаунт уже существует, нажмите 'Вход'");
      }

      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("user_data", JSON.stringify(data.user));
      // console.log("Access Token:", data.access_token);
      // console.log("Refresh Token:", data.refresh_token);

      navigate("/account", {
        state: {
          first_name: formData.first_name,
          middle_name: formData.middle_name,
          isu_number: formData.isu_number,
          email: formData.email,
          phone_number: formData.phone_number,
          last_name: formData.last_name,
        },
      });
    } catch (error) {
      setError(error.message); // Устанавливаем сообщение об ошибке
    }
  };

  return (
    <section className="eight_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="reg_all">
              <h1 className="infochemedu">
                <Link to="/" className="infochemedu">
                  InfochemistryEdu
                </Link>
              </h1>

              <h2 className="create_acc">СОЗДАЙТЕ АККАУНТ</h2>
              <div className="action_btns_reg">
                <Link to="/registration" className="action_btn1_reg">
                  Регистрация
                </Link>
                <Link to="/login" className="action_btn2_reg">
                  Вход
                </Link>
              </div>
              <form onSubmit={handleSubmit} className="form">
                <div className="column">
                  <div className="input-box">
                    <input
                      type="text"
                      name="middle_name"
                      placeholder="Фамилия"
                      required
                      value={formData.middle_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-box">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="Имя"
                      required
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Отчество"
                      required
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-box">
                    <input
                      type="tel"
                      name="phone_number"
                      placeholder="Телефон"
                      required
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <input
                      type="email"
                      name="email"
                      placeholder="Почта"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-box">
                    <input
                      type="text"
                      name="isu_number"
                      placeholder="Номер ИСУ"
                      required
                      value={formData.isu_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <input
                      type="text"
                      name="password1"
                      placeholder="Пароль"
                      required
                      value={formData.password1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      name="password2"
                      placeholder="Пароль повторно"
                      required
                      value={formData.password2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button className="reg_into_acc" type="submit">
                  Зарегистрироваться
                </button>
              </form>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
          <div className="col-md-6">
            <img className="reg_image" src="./img/reg.png" alt="" />
          </div>
        </div>
        <div className="login_small">
          <Link to="/login">
            <span className="login_small_text">Войти</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Registration;
