import HeaderAcc from "../navbaracc/navbaracc";
import Uspev from "../uspev/uspev";

const UspevPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Uspev />
      </div>
    </div>
  );
};

export default UspevPage;
