import HeaderAcc from "../navbaracc/navbaracc";
import Physic from "../physic/physic";

const PhysicPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Physic />
      </div>
    </div>
  );
};

export default PhysicPage;
