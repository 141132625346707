import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { refreshTokenIfNeeded, getAccessToken } from "../auth"; // Импортируем нужные функции
import "./studentDetails.css";

function StudentDetails() {
  const { pk } = useParams();
  const [studentInfo, setStudentInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [labs, setLabs] = useState([]);
  const [admissionScores, setAdmissionScores] = useState({});
  const [visitingStatuses, setVisitingStatuses] = useState({});
  const [practiceScores, setPracticeScores] = useState({});
  const [reportScores, setReportScores] = useState({});

  const handleVisitingChange = (labId) => {
    setVisitingStatuses((prevStatuses) => ({
      ...prevStatuses,
      [labId]: !prevStatuses[labId], // Инвертируем значение посещения
    }));
  };

  const handleAdmissionScoreChange = (e, labId) => {
    const value = e.target.value.trim();
    setAdmissionScores((prevScores) => ({
      ...prevScores,
      [labId]: value === "" ? 0 : parseInt(value),
    }));
  };

  const handlePracticeScoreChange = (e, labId) => {
    const value = e.target.value.trim();
    setPracticeScores((prevScores) => ({
      ...prevScores,
      [labId]: value === "" ? 0 : parseInt(value),
    }));
  };

  const handleReportScoreChange = (e, labId) => {
    const value = e.target.value.trim();
    setReportScores((prevScores) => ({
      ...prevScores,
      [labId]: value === "" ? 0 : parseInt(value),
    }));
  };

  const toggleAdminStatus = () => {
    setIsAdmin(!isAdmin);
  };

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const saveLabInfo = async (labId) => {
    try {
      let token = getAccessToken();
      const labToUpdate = labs.find((lab) => lab.pk === labId);

      const updatedLab = {
        admission_score: admissionScores[labId] || labToUpdate.admission_score,
        visiting: visitingStatuses[labId] || false,
        practice_score: practiceScores[labId] || labToUpdate.practice_score,
        report_score: reportScores[labId] || labToUpdate.report_score,
      };

      let response = await fetch(
        `https://www.infochemistryweb.ru/api/students/${pk}/labs/${labId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedLab),
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        if (token) {
          response = await fetch(
            `https://www.infochemistryweb.ru/api/students/${pk}/labs/${labId}`,
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updatedLab),
            }
          );
        } else {
          throw new Error("Ошибка при обновлении токена");
        }
      }

      if (!response.ok) {
        throw new Error("Ошибка при обновлении данных");
      }

      const responseData = await response.json();
      console.log("Ответ от сервера:", responseData);

      window.location.reload();
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
    }
  };

  useEffect(() => {
    const fetchStudentLabs = async () => {
      try {
        let token = getAccessToken();
        let response = await fetch(
          `https://www.infochemistryweb.ru/api/students/${pk}/labs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          token = await refreshTokenIfNeeded();
          if (token) {
            response = await fetch(
              `https://www.infochemistryweb.ru/api/students/${pk}/labs`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        }

        if (!response.ok) {
          throw new Error("Ошибка при обновлении данных");
        }

        const data = await response.json();
        console.log("Ответ от сервера:", data);

        const processedLabs = data.labs.map((lab, index) => ({
          ...lab,
          original_pk: lab.pk,
          pk: index + 1,
        }));
        console.log(processedLabs);
        setLabs(processedLabs);
      } catch (error) {
        console.error(
          "Ошибка при получении данных о лабораторных работах студента:",
          error
        );
      }
    };
    fetchStudentLabs();
  }, [pk]);

  const handleSave = async () => {
    try {
      let token = getAccessToken();
      let response = await fetch(
        `https://www.infochemistryweb.ru/api/students/${pk}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_admin: isAdmin,
          }),
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        if (token) {
          response = await fetch(
            `https://www.infochemistryweb.ru/api/students/${pk}`,
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                is_admin: isAdmin,
              }),
            }
          );
        } else {
          throw new Error("Ошибка при обновлении токена");
        }
      }

      if (!response.ok) {
        throw new Error("Ошибка при сохранении данных");
      }

      const updatedStudentInfo = { ...studentInfo, is_admin: isAdmin };
      setStudentInfo(updatedStudentInfo);
    } catch (error) {
      console.error("Ошибка при сохранении данных:", error);
    }
  };

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        let token = getAccessToken();
        let response = await fetch(
          `https://www.infochemistryweb.ru/api/students/${pk}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          token = await refreshTokenIfNeeded();
          if (token) {
            response = await fetch(
              `https://www.infochemistryweb.ru/api/students/${pk}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        }

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        setStudentInfo(data);
        setIsAdmin(data.is_admin);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setLoading(false);
      }
    };

    fetchStudentInfo();
  }, [pk]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (!studentInfo) {
    return <p>Информация о студенте не найдена.</p>;
  }

  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-11">
            <h1 className="acc_header header_stud_det">
              Личный кабинет
              {/* <button onClick={displayLabScores}>Вывести оценки</button> */}
            </h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/professor">
            <button className="acc_acc">Аккаунт</button>
          </Link>
          <Link to="/pdf_upload">
            <button className="acc_usp usp_lab">Лабораторные</button>
          </Link>
          <Link to="/mygroups">
            <button className="acc_groups">Мои группы</button>
          </Link>
          <Link to="/students_groups">
            <button className="acc_stud">Студенты</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="all_info">
          <h2 className="billings">
            {studentInfo.first_name} {studentInfo.middle_name}{" "}
            {studentInfo.last_name}
            <br></br>
            Почта: {studentInfo.email}
            <br></br>
            Номер ИСУ: {studentInfo.isu_number}
            <br></br>
            Номер телефона: {studentInfo.phone_number}
            <br></br>
            ВК: {studentInfo.vk_link}
            <br></br>
            Телеграм: {studentInfo.tg_link}
            <br></br>
            <div className="admin_checkbox">
              <input
                type="checkbox"
                id="adminCheckbox"
                checked={isAdmin}
                onChange={toggleAdminStatus}
              />
              <label htmlFor="adminCheckbox">
                <span className="make_prepod">Сделать преподавателем</span>{" "}
              </label>
              <Link to={`/bachelors1`}>
                <button onClick={handleSave} className="save_button_for_admin">
                  Сохранить
                </button>
              </Link>
              <br></br>
            </div>
            {labs && labs.length > 0 && (
              <div className="labs_info">
                <h3 className="billings">Информация о лабораторных работах:</h3>
                <ul className="balldwin">
                  {labs.map((lab) => (
                    <li className="billings  " key={lab.pk}>
                      <p className="name_of_lab_stud">
                        Название лабораторной работы: {lab.laba.name}
                      </p>
                      <p>
                        Допуск: {lab.admission_score}{" "}
                        <label htmlFor={`admissionScore${lab.pk}`}>
                          <input
                            type="text"
                            id={`admissionScore${lab.pk}`}
                            value={admissionScores[lab.pk] || ""} // Используйте admissionScores для установки значения
                            onChange={(e) =>
                              handleAdmissionScoreChange(e, lab.pk)
                            } // Передайте labId
                          />
                        </label>
                      </p>
                      <p>
                        Практика: {lab.practice_score}{" "}
                        <label htmlFor={`practiceScore${lab.pk}`}>
                          <input
                            type="text"
                            id={`practiceScore${lab.pk}`}
                            value={practiceScores[lab.pk] || ""} // Используйте practiceScores для установки значения
                            onChange={(e) =>
                              handlePracticeScoreChange(e, lab.pk)
                            } // Передайте labId
                          />
                        </label>
                      </p>
                      {/* <p>Отчет: {lab.report}</p> */}
                      <p>
                        Ссылка на отчет:{" "}
                        <a
                          href={lab.report_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Открыть отчет
                        </a>{" "}
                      </p>
                      <p>
                        Оценка за отчет: {lab.report_score}{" "}
                        <label htmlFor={`reportScore${lab.pk}`}>
                          <input
                            type="text"
                            id={`reportScore${lab.pk}`}
                            value={reportScores[lab.pk] || ""} // Используйте reportScores для установки значения
                            onChange={(e) => handleReportScoreChange(e, lab.pk)} // Передайте labId
                          />
                        </label>
                      </p>
                      <p>
                        Посещение:{" "}
                        <label>
                          <input
                            type="checkbox"
                            checked={visitingStatuses[lab.pk] || false}
                            onChange={() => handleVisitingChange(lab.pk)}
                          />
                        </label>
                      </p>

                      {/* <p>Тест: {lab.test_results}</p> */}

                      <button
                        className="save_button_for_labs_stud"
                        onClick={() => saveLabInfo(lab.pk)}
                      >
                        Сохранить
                      </button>
                      {lab.tests_results && lab.tests_results.length > 0 && (
                        <div>
                          <h4 className="billings">Результаты теста:</h4>
                          <div>
                            <ul>
                              {lab.tests_results.map((test, index) => {
                                const percentage =
                                  (test.score / test.max_score) * 100;
                                return (
                                  <li
                                    className="balling_test_scores"
                                    key={index}
                                  >
                                    {/* <p>Дата: {test.date}</p> */}
                                    <p>Максимальный балл: {test.max_score}</p>
                                    <p>Балл: {test.score}</p>
                                    <p>
                                      Процент прохождения:{" "}
                                      {percentage.toFixed(2)}%
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                      {/* Другие данные лабораторной работы */}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {/* <Link to={`/bachelors1/${pk}/tests`}>
              <button className="test_results_button_yeah">
                Результаты тестов
              </button>
            </Link> */}
            <Link to={`/bachelors1`}>
              <button className="test_results_button_yeah">Назад</button>
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails;
