import Account from "../account/account";
import HeaderAcc from "../navbaracc/navbaracc";

const AccountPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Account />
      </div>
    </div>
  );
};

export default AccountPage;
