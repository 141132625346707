import MyLabs from "../mylabs/mylabs";
import HeaderAcc from "../navbaracc/navbaracc";

const MyLabsPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="prof_page">
        <MyLabs />
      </div>
    </div>
  );
};

export default MyLabsPage;
