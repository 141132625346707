import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./uspev.css";
import { refreshTokenIfNeeded, getAccessToken } from "../auth"; // Импортируем нужные функции

function Uspev() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [activeTable, setActiveTable] = useState("theory");
  const [labs, setLabs] = useState([]);

  const showTable = (table) => {
    setActiveTable(table);
  };

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    async function fetchStudentLabs(studentId) {
      try {
        const token = getAccessToken();
        let response = await fetch(
          `https://www.infochemistryweb.ru/api/students/${studentId}/labs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          await refreshTokenIfNeeded();
          const newToken = getAccessToken();

          response = await fetch(
            `https://www.infochemistryweb.ru/api/students/${studentId}/labs`,
            {
              headers: {
                Authorization: `Bearer ${newToken}`,
              },
            }
          );
        }

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Ответ от сервера (fetchStudentLabs):", data);

        const labs = data.labs;
        if (Array.isArray(labs) && labs.length > 0) {
          setLabs(labs); // Устанавливаем данные в состояние
        } else {
          console.log("Студент не имеет лабораторных работ");
        }
      } catch (error) {
        console.error(
          "Ошибка при получении данных о лабораторных работах студента:",
          error
        );
      }
    }

    const student_info = JSON.parse(localStorage.getItem("user_data")) || {};
    const studentId = student_info.pk;
    if (studentId) {
      fetchStudentLabs(studentId);
    } else {
      console.log("PK студента не найден");
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="acc_header">Личный кабинет</h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/account">
          <button className="acc_acc_lab">Аккаунт</button>
        </Link>
        <Link to="/uspev">
          <button className="acc_usp acc_usp_usp">Успеваемость</button>
        </Link>
        <Link to="/mylabs">
          <button className="acc_lab">Мои лабораторные</button>
        </Link>
        <Link to="/acctests">
          <button className="acc_test">Тесты</button>
        </Link>
        <Link to="/acclabs">
          <button className="acc_doc_lab_lab">Документация</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="uspevaemost">
        <h1 className="my_progr">Моя Успеваемость</h1>
        <div className="buttons">
          <button
            className={`buttons_ter1 ${
              activeTable === "theory" ? "active" : ""
            }`}
            onClick={() => showTable("theory")}
          >
            Теория
          </button>
          <button
            className={`buttons_ter2 ${
              activeTable === "practice" ? "active" : ""
            }`}
            onClick={() => showTable("practice")}
          >
            Практика
          </button>
          <button
            className={`buttons_ter3 ${activeTable === "itog" ? "active" : ""}`}
            onClick={() => showTable("itog")}
          >
            Итог
          </button>
        </div>

        {activeTable === "theory" && (
          <table className="table_theory">
            <thead>
              <tr>
                <th className="some_data_in_table name_of_laborat">
                  НАЗВАНИЕ РАБОТЫ
                </th>
                <th className="some_data_in_table date_of_lab">ДАТА</th>
                <th className="some_data_in_table posesh2">ПОС</th>
                <th className="some_data_in_table testovski2">ТЕСТ</th>
                <th className="some_data_in_table">ДОПУСК</th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  {labs.length > 0 && (
                    <>
                      <td>{labs[0].laba.name}</td>
                      <td>
                        <span>
                          {labs[0].tests_results[0].date
                            .split(" ")[0] // Разбиваем дату и время
                            .split("-") // Разбиваем дату по "-"
                            .slice(1) // Выбираем элементы начиная с индекса 1 (месяц, день, год)
                            .reverse() // Меняем порядок элементов
                            .join(".")}
                        </span>
                      </td>
                      <td>
                        <span>{labs[0].visiting ? "+" : "-"}</span>
                      </td>
                      <td>
                        <span>
                          {labs[0].tests_results &&
                          labs[0].tests_results.length > 0 &&
                          labs[0].tests_results[0]?.score
                            ? Math.ceil(labs[0].tests_results[0].score / 10)
                            : " "}
                        </span>
                      </td>
                      <td>
                        <span>{labs[0].admission_score}</span>
                      </td>
                    </>
                  )}
                </tr>
                {labs.slice(1).map((lab, index) => (
                  <tr key={index}>
                    <td>{lab.laba.name}</td>
                    <td>
                      <span>
                        {lab.tests_results[0]?.date
                          .split(" ")[0] // Разбиваем дату и время
                          .split("-") // Разбиваем дату по "-"
                          .slice(1) // Выбираем элементы начиная с индекса 1 (месяц, день, год)
                          .reverse() // Меняем порядок элементов
                          .join(".")}
                      </span>
                    </td>
                    <td>
                      <span> {lab.visiting ? "+" : "-"}</span>
                    </td>
                    <td>
                      <span>
                        <span>
                          {lab.tests_results &&
                          lab.tests_results.length > 0 &&
                          lab.tests_results[0]?.score
                            ? Math.ceil(
                                (lab.tests_results[0].score /
                                  lab.tests_results[0].max_score || 0) / 10
                              )
                            : " "}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span> {lab.admission_score}</span>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        )}
        {activeTable === "practice" && (
          <table className="table_practice">
            <thead>
              <tr>
                <th className="some_data_in_table work_name">
                  НАЗВАНИЕ РАБОТЫ
                </th>
                <th className="some_data_in_table otchetnik">ОТЧЕТ</th>
                <th className="some_data_in_table">БАЛЛЫ ЗА ОТЧЕТ</th>
                <th className="some_data_in_table">ВЫПОЛ НЕНИЕ</th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  {labs.length > 0 && (
                    <>
                      <td>{labs[0].laba.name}</td>
                      <td>
                        <span>{labs.report ? "Не загружен" : "Загружен"}</span>
                      </td>
                      <td>
                        <span>{labs[0].report_score}</span>
                      </td>
                      <td>
                        <span>{labs[0].practice_score}</span>
                      </td>
                    </>
                  )}
                </tr>
                {labs.slice(1).map((lab, index) => (
                  <tr key={index}>
                    <td>{lab.laba.name}</td>
                    <td>
                      <span>{lab.report ? "Загружен" : "Не загружен"}</span>
                    </td>
                    <td>
                      <span>
                        <span>{lab.report_score}</span>
                      </span>
                    </td>
                    <td>
                      <span>{lab.practice_score}</span>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        )}
        {activeTable === "itog" && (
          <table className="table_itog">
            <thead>
              <tr>
                <th className="some_data_in_table work_name">
                  НАЗВАНИЕ РАБОТЫ
                </th>
                <th className="some_data_in_table">СУММА БАЛЛОВ</th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  {labs.length > 0 && (
                    <>
                      <td>{labs[0].laba.name}</td>
                      <td>
                        <span>
                          {Math.ceil(
                            (labs[0].admission_score || 0) +
                              (labs[0].practice_score || 0) +
                              (labs[0].report_score || 0) +
                              ((labs[0].tests_results &&
                                labs[0].tests_results.length > 0 &&
                                labs[0].tests_results[0]?.score) ||
                                0) /
                                10
                          )}
                        </span>
                      </td>
                    </>
                  )}
                </tr>
                {labs.slice(1).map((lab, index) => (
                  <tr key={index}>
                    <td>{lab.laba.name}</td>
                    <td>
                      <span>
                        {Math.ceil(
                          (lab.admission_score || 0) +
                            (lab.practice_score || 0) +
                            (lab.report_score || 0) +
                            ((lab.tests_results &&
                              lab.tests_results.length > 0 &&
                              lab.tests_results[0]?.score) ||
                              0) /
                              10
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <strong>Итого</strong>
                  </td>
                  <td>
                    <strong>
                      {Math.ceil(
                        labs.reduce(
                          (sum, lab) =>
                            sum +
                            (lab.admission_score || 0) +
                            (lab.practice_score || 0) +
                            (lab.report_score || 0) +
                            ((lab.tests_results &&
                              lab.tests_results.length > 0 &&
                              lab.tests_results[0]?.score) ||
                              0) /
                              10,
                          0
                        )
                      )}
                    </strong>
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Uspev;
