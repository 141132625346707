import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { refreshTokenIfNeeded, getAccessToken } from "../auth"; // Импортируем нужные функции
import "./testComponent.css";

function TestComponent() {
  const { pk } = useParams();
  const [testInfo, setTestInfo] = useState(null);
  const [isMenuActive, setMenuActive] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const handleAnswerSelection = (answerId) => {
    let updatedAnswers = [...selectedAnswers];

    const answerIndex = updatedAnswers.findIndex((id) => id === answerId);

    if (answerIndex !== -1) {
      updatedAnswers = updatedAnswers.filter((id) => id !== answerId);
    } else {
      updatedAnswers.push(answerId);
    }

    setSelectedAnswers(updatedAnswers);
  };

  const sendAnswersToServer = async (answers) => {
    try {
      let token = getAccessToken();
      const url = `https://www.infochemistryweb.ru/api/tests/${pk}/submit`;

      const dataToSend = {
        answers: answers,
      };

      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        if (token) {
          response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(dataToSend),
          });
        } else {
          throw new Error("Ошибка при обновлении токена");
        }
      }

      if (!response.ok) {
        throw new Error("Ошибка при отправке ответов на сервер");
      }

      const data = await response.json();
      console.log("Успешный ответ сервера:", data); // Вывод успешного ответа сервера в консоль

      // Сохраняем результаты в localStorage
      localStorage.setItem("max_score", data.max_score);
      localStorage.setItem("score", data.score);
      localStorage.setItem("pk_test", data.pk_test);
      localStorage.setItem("attempts", data.attempts);

      window.location.href = "/result";
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  const handleSendAnswers = () => {
    sendAnswersToServer(selectedAnswers);
    console.log("Отправляем на сервер:", { answers: selectedAnswers }); // Отправляем объект с ключом answers
    setSelectedAnswers([]);
  };

  useEffect(() => {
    const fetchTestInfo = async () => {
      try {
        let token = getAccessToken();
        const url = `https://www.infochemistryweb.ru/api/tests/${pk}`;
        let response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          token = await refreshTokenIfNeeded();
          if (token) {
            response = await fetch(url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        }

        if (!response.ok) {
          throw new Error("Ошибка при загрузке информации о тесте");
        }

        const data = await response.json();
        console.log(data);
        setTestInfo(data);
      } catch (error) {
        console.error("Ошибка:", error);
      }
    };
    fetchTestInfo();
  }, [pk]);

  if (!testInfo) {
    return <div>Loading...</div>;
  }

  // Проверяем, есть ли еще попытки
  if (testInfo.attempts === 0) {
    return (
      <div className="attemps_0">
        <span className="span_0">Вы истратили все попытки</span>
        <Link to={`/acctests`}>
          <button className="test_results_button_yeah button_res_2">
            Назад
          </button>
        </Link>
      </div>
    );
  }

  const sortedQuestions = [...testInfo.questions].sort((a, b) => a.pk - b.pk);

  console.log(sortedQuestions);
  console.log("TESTTEST");

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="acc_header">Личный кабинет</h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        <Link to="/account">
          <button className="acc_acc_test">Аккаунт</button>
        </Link>

        <Link to="/uspev">
          <button className="acc_usp">Успеваемость</button>
        </Link>

        <Link to="/mylabs">
          <button className="acc_lab">Мои лабораторные</button>
        </Link>

        <Link to="/acctests">
          <button className="acc_test_test">Тесты</button>
        </Link>

        <Link to="/acclabs">
          <button className="acc_doc">Документация</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="one_test">
        <h2>Тест "{testInfo.name}"</h2>
        {/* <p className="poposha_test">Попытки: {testInfo.attempts}</p> */}
        <ul>
          {sortedQuestions &&
            sortedQuestions.map((question) => {
              const correctAnswersCount = question.answers.filter(
                (answer) => answer.flag
              ).length;

              return (
                <li key={question.pk} className="balling_stones_1">
                  <h3
                    className="ques"
                    onClick={() => {
                      const checkbox = document.querySelector(
                        `input[value="${question.pk}"]`
                      );
                      if (checkbox) {
                        checkbox.checked = !checkbox.checked;
                        handleAnswerSelection(question.pk);
                      }
                    }}
                  >
                    {question.question}
                  </h3>
                  {question.image && (
                    <img
                      className="question_image"
                      src={question.image}
                      alt="Question"
                    />
                  )}
                  {question.answers && (
                    <ul>
                      {question.answers.map((answer) => (
                        <li key={answer.pk} className="balling_stones_2">
                          <label>
                            <input
                              type={
                                correctAnswersCount === 1 ? "radio" : "checkbox"
                              }
                              value={answer.pk}
                              checked={selectedAnswers.includes(answer.pk)}
                              onChange={() => handleAnswerSelection(answer.pk)}
                            />
                            {answer.answer}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!question.answers && <p>No answers available</p>}
                </li>
              );
            })}
        </ul>
        <div className="center_da_button">
          <button
            className="send_answers_to_server"
            onClick={handleSendAnswers}
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
}

export default TestComponent;
