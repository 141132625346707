import HeaderAcc from "../navbaracc/navbaracc";
import Result from "../result/result";

const ResultPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Result />
      </div>
    </div>
  );
};

export default ResultPage;
