import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./addstudentstogroup.css";

function AddStudentsToGroup() {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentsInGroup, setStudentsInGroup] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { groupId } = useParams();
  const [groupInfo, setGroupInfo] = useState(null);
  const [isMenuActive, setMenuActive] = useState(false);
  const [searchText, setSearchText] = useState("");

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          `https://www.infochemistryweb.ru/api/students`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        if (Array.isArray(data.students)) {
          // Сортировка студентов по первой букве middle_name
          const sortedStudents = data.students.sort((a, b) =>
            a.middle_name.localeCompare(b.middle_name)
          );
          setStudents(sortedStudents);
        } else {
          console.error(
            "Полученные данные не являются массивом студентов:",
            data
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchGroupInfo = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          `https://www.infochemistryweb.ru/api/groups/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        if (Array.isArray(data.students)) {
          setStudentsInGroup(data.students);
        } else {
          console.error(
            "Полученные данные не являются массивом студентов:",
            data
          );
        }
        setGroupInfo(data); // Установка информации о группе
      } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
      }
    };

    fetchGroupInfo();
  }, [groupId]);

  const handleCheckboxChange = (studentId) => {
    const isSelected = selectedStudents.includes(studentId);
    let updatedSelectedStudents;

    if (isSelected) {
      updatedSelectedStudents = selectedStudents.filter(
        (selectedId) => selectedId !== studentId
      );
    } else {
      updatedSelectedStudents = [...selectedStudents, studentId];
    }

    setSelectedStudents(updatedSelectedStudents);
  };

  const handleAddStudents = async () => {
    try {
      const patchData = {
        students: [
          ...groupInfo.students.map((student) => student.pk),
          ...selectedStudents,
        ], // Добавляем выбранных новых студентов к текущим студентам группы
      };

      const response = await fetch(
        `https://www.infochemistryweb.ru/api/groups/${groupId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(patchData),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка при выполнении запроса");
      }

      if (response.ok) {
        console.log("Студенты успешно добавлены в группу");
        setSelectedStudents([]); // Очищаем выбранных студентов
      } else {
        throw new Error("Ошибка при добавлении студентов в группу");
      }
    } catch (error) {
      console.error("Ошибка при добавлении студентов:", error);
    }
  };

  // Фильтрация студентов по введенному тексту
  const filteredStudents = students.filter((student) =>
    `${student.middle_name} ${student.first_name} ${student.last_name}`
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-11">
            <h1 className="acc_header">Личный кабинет</h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/professor">
            <button className="acc_acc usp_lab_acc">Аккаунт</button>
          </Link>
          <Link to="/pdf_upload">
            <button className="acc_usp usp_lab">Лабораторные</button>
          </Link>
          <Link to="/mygroups">
            <button className="acc_groups">Мои группы</button>
          </Link>
          <Link to="/students_groups">
            <button className="acc_stud acc_stud_balling">Студенты</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="add_students_to_group_ball">
          <h2 className="add_to_group_studs">Добавить студентов в группу</h2>
          <input
            className="search_for_studs_balls"
            type="text"
            placeholder="Поиск по студентам"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {loading ? (
            <p>Загрузка...</p>
          ) : (
            <div className="row">
              {filteredStudents.map((student) => (
                <div className="col-md-5 col-xs-11 col-sm-11" key={student.pk}>
                  <input
                    type="checkbox"
                    id={`student-${student.pk}`}
                    checked={selectedStudents.includes(student.pk)}
                    onChange={() => handleCheckboxChange(student.pk)}
                  />
                  <label
                    className="binbinbinchil"
                    htmlFor={`student-${student.pk}`}
                  >
                    {student.middle_name} {student.first_name}{" "}
                    {student.last_name}
                  </label>
                </div>
              ))}
            </div>
          )}
          <button className="add_studs_to_balls" onClick={handleAddStudents}>
            Добавить выбранных студентов
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddStudentsToGroup;
