import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import userActionsReducer from "./reducers/userActions";

const store = configureStore({
  reducer: {
    user: userReducer,
    userActions: userActionsReducer,
  },
});

export default store;
