import Main from "../main/main";
import Sci from "../sci/sci";
import Cards from "../cards/cards";
import Learn from "../learn/learn";
import Path from "../path/path";
import Plan from "../plan/plan";
import Contacts from "../contacts/contacts";
import Header from "../navbar/navbar";

const MainPage = () => {
  return (
    <div className="main">
      <Header />
      <Main />
      <Sci />
      <Cards />
      <Learn />
      <Path />
      <Plan />
      <Contacts />
    </div>
  );
};

export default MainPage;
