import React, { useState, useEffect } from "react";

function StudentsListParsed2({ groupId }) {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [labs, setLabs] = useState([]);
  const [name, setName] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("access_token");

        // Загружаем студентов
        const studentsResponse = await fetch(
          "https://www.infochemistryweb.ru/api/students",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!studentsResponse.ok) {
          throw new Error("Ошибка при загрузке студентов");
        }
        const studentsData = await studentsResponse.json();
        setStudents(studentsData.students);

        // Загружаем лабораторные работы
        const labsResponse = await fetch(
          "https://www.infochemistryweb.ru/api/labs",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!labsResponse.ok) {
          throw new Error("Ошибка при загрузке лабораторных работ");
        }
        const labsData = await labsResponse.json();
        setLabs(labsData.labs);

        setLoading(false);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudents((prevSelectedStudents) => {
      if (prevSelectedStudents.includes(studentId)) {
        return prevSelectedStudents.filter((id) => id !== studentId);
      } else {
        return [...prevSelectedStudents, studentId];
      }
    });
  };

  const handleAddToGroup = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `https://www.infochemistryweb.ru/api/groups/${groupId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            students: selectedStudents,
            labs: labs.map((lab) => lab.link), // Пример преобразования списка лабораторных работ
            name: name,
            creator_id: creatorId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка при добавлении студентов в группу");
      }

      // Успешно добавлено
      console.log("Студенты успешно добавлены в группу");
      // Очищаем выбранных студентов после добавления в группу
      setSelectedStudents([]);
    } catch (error) {
      console.error("Ошибка при добавлении студентов в группу:", error);
    }
  };

  return (
    <div>
      <h2>Добавление студентов в группу</h2>
      {loading ? (
        <p>Загрузка...</p>
      ) : (
        <div>
          {students.map((student) => (
            <div key={student.id}>
              <label>
                <input
                  type="checkbox"
                  value={student.id}
                  onChange={() => handleCheckboxChange(student.id)}
                  checked={selectedStudents.includes(student.id)}
                />
                {student.name}
              </label>
            </div>
          ))}
          <button onClick={handleAddToGroup}>Добавить в группу</button>
        </div>
      )}
    </div>
  );
}
export default StudentsListParsed2;
