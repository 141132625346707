import MyGroups from "../mygroups/mygroups";
import HeaderAcc from "../navbaracc/navbaracc";

const MyGroupsPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="prof_page">
        <MyGroups />
      </div>
    </div>
  );
};

export default MyGroupsPage;
