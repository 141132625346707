import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./groupMembers.css";

function GroupMembers() {
  const { pk } = useParams();
  const [groupInfo, setGroupInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMenuActive, setMenuActive] = useState(false);
  const [activeTable, setActiveTable] = useState("theory"); // Добавляем состояние для активной таблицы
  const [labs, setLabs] = useState([]);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const showTable = (table) => {
    setActiveTable(table);
  };

  useEffect(() => {
    async function fetchLabs() {
      try {
        const token = localStorage.getItem("access_token"); // Получение access_token из localStorage
        const response = await fetch(
          "https://www.infochemistryweb.ru/api/labs",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Добавление access_token в заголовок запроса
            },
          }
        );

        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchLabs(); // Повторная попытка отправить запрос с обновленным токеном
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при обновлении данных");
        }

        if (!response.ok) {
          throw new Error(`Ошибка: ${response.statusText}`);
        }
        const data = await response.json();

        setLabs(data.labs.map(({ name, link }) => ({ name, link })));
      } catch (error) {
        console.error("Ошибка при загрузке лабораторных работ:", error);
      }
    }

    fetchLabs();
  }, []);

  useEffect(() => {
    const fetchGroupInfo = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          `https://www.infochemistryweb.ru/api/groups/${pk}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await fetch(
            "https://www.infochemistryweb.ru/api/auth/token/refresh",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          if (refreshResponse.ok) {
            const { access_token } = await refreshResponse.json();
            localStorage.setItem("access_token", access_token);
            fetchGroupInfo(); // Повторная попытка отправить запрос с обновленным токеном
          } else {
            throw new Error("Ошибка при обновлении токена");
          }
        } else if (!response.ok) {
          throw new Error("Ошибка при обновлении данных");
        }

        if (!response.ok) {
          throw new Error("Ошибка при выполнении запроса");
        }

        const data = await response.json();
        // console.log(data);
        setGroupInfo(data);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при получении информации о группе:", error);
        setLoading(false);
      }
    };

    fetchGroupInfo();
  }, [pk]);

  useEffect(() => {
    // Проверка наличия информации о группе
    if (groupInfo) {
      // Перебор студентов группы и вывод их ID в консоль
      groupInfo.students.forEach((student) => {
        console.log("ID студента:", student.pk);
      });
    }
  }, [groupInfo]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-11">
          <h1 className="acc_header">Личный кабинет </h1>
        </div>
      </div>
      <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
        {" "}
        <Link to="/professor">
          <button className="acc_acc usp_lab_acc">Аккаунт</button>
        </Link>
        <Link to="/pdf_upload">
          <button className="acc_usp usp_lab_dab">Лабораторные</button>
        </Link>
        <Link to="/mygroups">
          <button className="acc_groups">Мои группы</button>
        </Link>
        <Link to="/students_groups">
          <button className="acc_stud">Студенты</button>
        </Link>
      </ul>
      <div
        className={`hamburger_acc ${
          isMenuActive ? "hamburger_acc_active" : ""
        }`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="groupMembersClass">
        <h2 className="group_members_list">
          Участники группы {groupInfo?.name}
        </h2>
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <div>
            {groupInfo?.students.map((student) => (
              <div key={student.pk}>
                <Link
                  className="student_of_the_group_link"
                  to={`/bachelors1/${student.pk}`}
                >
                  <p className="student_of_the_group">
                    {student.first_name} {student.middle_name}{" "}
                    {student.last_name}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        )}
        <Link to={`/mygroups/${pk}/appoint`}>
          <button className="naz_lab">Назначить лабораторные работы</button>
        </Link>
        <br></br>
        {/* <Link to={`/bachelors2`}>
          <button className="naz_lab">Добавить студентов в группу</button>
        </Link> */}
        <Link to={`/mygroups/${pk}/addstudentstogroup`}>
          <button className="naz_lab">Добавить студентов</button>
        </Link>
      </div>
    </div>
  );
}

export default GroupMembers;
