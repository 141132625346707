import Login from "../login/login";

const LoginPage = () => {
  return (
    <div className="log_page">
      <Login />
    </div>
  );
};

export default LoginPage;
