import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accountData: null,
  studentList: [],
  loginError: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUserSuccess: (state, action) => {
      state.user = action.payload;
      state.loginError = null;
    },
    loginUserFailure: (state, action) => {
      state.user = null;
      state.loginError = action.payload;
    },
    // logoutUser: (state) => {
    //   state.user = null;
    //   state.loginError = null;
    // },
  },
});

// export const { loginUserSuccess, loginUserFailure, logoutUser } =
//   userSlice.actions;

export const loginUserSuccess = (user) => ({
  type: "LOGIN_USER_SUCCESS",
  payload: user,
});

export const loginUserFailure = (error) => ({
  type: "LOGIN_USER_FAILURE",
  payload: error,
});

export default userSlice.reducer;
