import "./app.css";

import {
  MainPage,
  RegistrationPage,
  LoginPage,
  AccountPage,
  RedactPage,
} from "../pages";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TestsPage from "../pages/TestsPage";
import Acclabs from "../pages/LabsPage";
import TestPage from "../pages/TestPage";
import TestDbPage from "../pages/TestBdPage";
import ResultPage from "../pages/ResultPage";
import data from "../test/data";
import ProfessorPage from "../pages/ProfessorPage";
import InorganicPage from "../pages/InorganicPage";
import StudentsGroupsPage from "../pages/StudentsGroups";
import StudentsListParsedPage from "../pages/StudentsListParsedPage";
import StudentDetails from "../studentDetails/studentDetails";
import TestsResultsPage from "../pages/TestsResultsPage";
import GroupingPage from "../pages/GroupingPage";
import MyLabsPage from "../pages/MyLabsPage";
import UspevPage from "../pages/UspevPage";
import MyGroupsPage from "../pages/MyGroupsPage";
import GroupMembersPage from "../pages/GroupMembersPage";
import PDFUploaderPage from "../pages/PDFUploaderPage";
import TestComponentPage from "../pages/TestComponentPage";
import AppointPage from "../pages/AppointPage";
import OrganicPage from "../pages/OrganicPage";
import AnalyticPage from "../pages/AnalyticPage";
import PhysicPage from "../pages/PhysicPage";
import TbvideosPage from "../pages/TbvideosPage";
import StudentsListParsed2Page from "../pages/StudentsListParsed2Page";
import AddStudentsToGroupPage from "../pages/AddStudentsToGroupPage";
import TokenRefresher from "../tokenRefresh";

function App() {
  return (
    <Router>
      <div className="app">
        <div className="main_page">
          <Routes>
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/redact" element={<RedactPage />} />
            <Route path="/acctests" element={<TestsPage />} />
            <Route path="/acclabs" element={<Acclabs />} />
            <Route path="/test" element={<TestPage data={data} />} />
            <Route path="/testdb" element={<TestDbPage />} />
            <Route path="/result" element={<ResultPage />} />
            <Route path="/professor" element={<ProfessorPage />} />
            <Route path="/inorganic" element={<InorganicPage />} />
            <Route path="/students_groups" element={<StudentsGroupsPage />} />
            <Route path="/bachelors1" element={<StudentsListParsedPage />} />
            <Route path="/bachelors1/:pk" element={<StudentDetails />} />
            <Route
              path="/bachelors1/:pk/tests"
              element={<TestsResultsPage />}
            />
            <Route path="/grouping" element={<GroupingPage />} />
            <Route path="/mylabs" element={<MyLabsPage />} />
            <Route path="/uspev" element={<UspevPage />} />
            <Route path="/mygroups" element={<MyGroupsPage />} />
            <Route path="/mygroups/:pk" element={<GroupMembersPage />} />
            <Route path="/pdf_upload" element={<PDFUploaderPage />} />
            <Route path="/acctests/:pk" element={<TestComponentPage />} />
            <Route
              path="/mygroups/:groupId/appoint"
              element={<AppointPage />}
            />
            <Route path="/organic" element={<OrganicPage />} />
            <Route path="/analytic" element={<AnalyticPage />} />
            <Route path="/physic" element={<PhysicPage />} />
            <Route path="/tbvideos" element={<TbvideosPage />} />
            <Route path="/bachelors2" element={<StudentsListParsed2Page />} />
            <Route
              path="/mygroups/:groupId/addstudentstogroup"
              element={<AddStudentsToGroupPage />}
            />
            <Route path="/refreshtoken" element={<TokenRefresher />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
export default App;
