import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./redact.css";
import { getAccessToken, refreshTokenIfNeeded } from "../auth";

function Redact() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    phone_number: "",
    acc_image: "",
    tg_link: "",
    vk_link: "",
    class_school: "",
    school: "",
    city: "",
  });

  const [userFullName, setUserFullName] = useState("");
  const [isuNumber, setIsuNumber] = useState("");

  const [isMenuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("user_data"));
    if (storedData) {
      const fullName = `${storedData.middle_name || ""} ${
        storedData.first_name || ""
      } ${storedData.last_name || ""}`;
      setUserFullName(fullName);

      const ISU_NUM = `${storedData.isu_number || ""}`;
      setIsuNumber(ISU_NUM);

      setUserData({
        email: storedData.email || "",
        phone_number: storedData.phone_number || "",
        acc_image: storedData.acc_image || "",
        tg_link: storedData.tg_link || "",
        vk_link: storedData.vk_link || "",
        class_school: storedData.class_school || "",
        school: storedData.school || "",
        city: storedData.city || "",
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let token = getAccessToken();
      let response = await fetch(
        "https://www.infochemistryweb.ru/api/auth/user",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.status === 401) {
        token = await refreshTokenIfNeeded();
        response = await fetch(
          "https://www.infochemistryweb.ru/api/auth/user",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(userData),
          }
        );
      }

      if (!response.ok) {
        throw new Error("Ошибка при обновлении данных");
      }

      const data = await response.json();
      if (data) {
        localStorage.setItem("user_data", JSON.stringify(data));
        navigate(-1, {
          state: {
            email: userData.email,
            phone_number: userData.phone_number,
            acc_image: userData.acc_image,
            tg_link: userData.tg_link,
            vk_link: userData.vk_link,
          },
        });
        console.log(localStorage);
      } else {
        console.error("Данные пользователя отсутствуют в ответе сервера");
      }
    } catch (error) {
      console.error("Произошла ошибка при выполнении запроса:", error);
    }
  };

  return (
    <div className="acc_main_section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="acc_header">Личный кабинет</h1>
          </div>
        </div>
        <ul className={`acc_list ${isMenuActive ? "acc_list_active" : ""}`}>
          {" "}
          <Link to="/account">
            <button className="acc_acc">Аккаунт</button>
          </Link>
          <Link to="/uspev">
            <button className="acc_usp">Успеваемость</button>
          </Link>
          <Link to="/mylabs">
            <button className="acc_lab">Мои лабораторные</button>
          </Link>
          <Link to="/acctests">
            <button className="acc_test">Тесты</button>
          </Link>
          <Link to="/acclabs">
            <button className="acc_doc">Документация</button>
          </Link>
        </ul>
        <div
          className={`hamburger_acc ${
            isMenuActive ? "hamburger_acc_active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="rect">
          {/* <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handlePhotoChange}
          /> */}
          <img className="acc_photo" src="img/chemical_9806743.png" alt="" />
          <h3 className="name_place">
            {userFullName}

            {/* <Link to="#"> */}
            <svg
              className="redact_svg"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.4549 5.41583C21.5499 5.56004 21.5922 5.73261 21.5747 5.90442C21.5573 6.07622 21.481 6.23673 21.3589 6.35883L12.1659 15.5508C12.0718 15.6448 11.9545 15.7121 11.8259 15.7458L7.99689 16.7458C7.87032 16.7788 7.73732 16.7782 7.61109 16.7439C7.48485 16.7096 7.36978 16.6429 7.27729 16.5504C7.18479 16.4579 7.1181 16.3429 7.08382 16.2166C7.04955 16.0904 7.04888 15.9574 7.08189 15.8308L8.08189 12.0028C8.11109 11.8879 8.16616 11.7812 8.24289 11.6908L17.4699 2.46983C17.6105 2.32938 17.8011 2.25049 17.9999 2.25049C18.1986 2.25049 18.3893 2.32938 18.5299 2.46983L21.3589 5.29783C21.3948 5.33383 21.4269 5.37337 21.4549 5.41583ZM19.7679 5.82783L17.9999 4.06083L9.48189 12.5788L8.85689 14.9718L11.2499 14.3468L19.7679 5.82783Z"
                fill="#263238"
              />
              <path
                d="M19.6406 17.1599C19.9139 14.8238 20.0012 12.4698 19.9016 10.1199C19.8994 10.0645 19.9087 10.0093 19.9288 9.95769C19.9489 9.90608 19.9795 9.85916 20.0186 9.81989L21.0026 8.83589C21.0295 8.80885 21.0636 8.79014 21.1008 8.78203C21.1381 8.77392 21.1769 8.77674 21.2126 8.79015C21.2483 8.80356 21.2794 8.827 21.3021 8.85764C21.3248 8.88828 21.3381 8.92483 21.3406 8.96289C21.5258 11.7541 21.4555 14.5564 21.1306 17.3349C20.8946 19.3569 19.2706 20.9419 17.2576 21.1669C13.7629 21.5539 10.2362 21.5539 6.74157 21.1669C4.72957 20.9419 3.10457 19.3569 2.86857 17.3349C2.45397 13.7903 2.45397 10.2095 2.86857 6.66489C3.10457 4.64289 4.72857 3.05789 6.74157 2.83289C9.39394 2.53877 12.0663 2.46752 14.7306 2.61989C14.7687 2.62262 14.8052 2.63623 14.8359 2.6591C14.8665 2.68196 14.8899 2.71313 14.9034 2.74891C14.9169 2.78468 14.9198 2.82357 14.9119 2.86096C14.9039 2.89835 14.8854 2.93268 14.8586 2.95989L13.8656 3.95189C13.8267 3.99064 13.7803 4.02101 13.7292 4.04113C13.6781 4.06125 13.6234 4.0707 13.5686 4.06889C11.3453 3.99331 9.11952 4.07853 6.90857 4.32389C6.26251 4.39539 5.65942 4.68261 5.19672 5.13914C4.73403 5.59567 4.43874 6.19485 4.35857 6.83989C3.95762 10.2682 3.95762 13.7316 4.35857 17.1599C4.43874 17.8049 4.73403 18.4041 5.19672 18.8606C5.65942 19.3172 6.26251 19.6044 6.90857 19.6759C10.2636 20.0509 13.7356 20.0509 17.0916 19.6759C17.7376 19.6044 18.3407 19.3172 18.8034 18.8606C19.2661 18.4041 19.5604 17.8049 19.6406 17.1599Z"
                fill="#263238"
              />
            </svg>
            {/* </Link> */}
          </h3>{" "}
          <h4 className="ISU">ИСУ {isuNumber}</h4>
          <div className="phone_balls_yk">
            <h2 className="acc_phone_num">Номер телефона</h2>
            <input
              type="text"
              name="phone_number"
              value={userData.phone_number}
              onChange={handleInputChange}
            />
          </div>
          <div className="mail_balls_yk">
            <h2 className="acc_mail">Почта</h2>
            <input
              type="text"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
            />
            <h4 className="social">Социальные сети</h4>
            <div className="social_urls">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_613_2663)">
                  <path
                    d="M16.0001 0.640137C7.51694 0.640137 0.640137 7.51694 0.640137 16.0001C0.640137 24.4833 7.51694 31.3601 16.0001 31.3601C24.4833 31.3601 31.3601 24.4833 31.3601 16.0001C31.3601 7.51694 24.4833 0.640137 16.0001 0.640137ZM21.9073 17.9697C21.9073 17.9697 23.2657 19.3105 23.6001 19.9329C23.6097 19.9457 23.6145 19.9585 23.6177 19.9649C23.7537 20.1937 23.7857 20.3713 23.7185 20.5041C23.6065 20.7249 23.2225 20.8337 23.0913 20.8433H20.6913C20.5249 20.8433 20.1761 20.8001 19.7537 20.5089C19.4289 20.2817 19.1089 19.9089 18.7969 19.5457C18.3313 19.0049 17.9281 18.5377 17.5217 18.5377C17.4701 18.5377 17.4188 18.5457 17.3697 18.5617C17.0625 18.6609 16.6689 19.0993 16.6689 20.2673C16.6689 20.6321 16.3809 20.8417 16.1777 20.8417H15.0785C14.7041 20.8417 12.7537 20.7105 11.0257 18.8881C8.91054 16.6561 7.00654 12.1793 6.99054 12.1377C6.87054 11.8481 7.11854 11.6929 7.38894 11.6929H9.81294C10.1361 11.6929 10.2417 11.8897 10.3153 12.0641C10.4017 12.2673 10.7185 13.0753 11.2385 13.9841C12.0817 15.4657 12.5985 16.0673 13.0129 16.0673C13.0906 16.0664 13.167 16.0467 13.2353 16.0097C13.7761 15.7089 13.6753 13.7809 13.6513 13.3809C13.6513 13.3057 13.6497 12.5185 13.3729 12.1409C13.1745 11.8673 12.8369 11.7633 12.6321 11.7249C12.715 11.6106 12.8242 11.5178 12.9505 11.4545C13.3217 11.2689 13.9905 11.2417 14.6545 11.2417H15.0241C15.7441 11.2513 15.9297 11.2977 16.1905 11.3633C16.7185 11.4897 16.7297 11.8305 16.6833 12.9969C16.6689 13.3281 16.6545 13.7025 16.6545 14.1441C16.6545 14.2401 16.6497 14.3425 16.6497 14.4513C16.6337 15.0449 16.6145 15.7185 17.0337 15.9953C17.0884 16.0296 17.1516 16.0479 17.2161 16.0481C17.3617 16.0481 17.8001 16.0481 18.9873 14.0113C19.3535 13.3558 19.6716 12.6745 19.9393 11.9729C19.9633 11.9313 20.0337 11.8033 20.1169 11.7537C20.1783 11.7224 20.2464 11.7065 20.3153 11.7073H23.1649C23.4753 11.7073 23.6881 11.7537 23.7281 11.8737C23.7985 12.0641 23.7153 12.6449 22.4145 14.4065L21.8337 15.1729C20.6545 16.7185 20.6545 16.7969 21.9073 17.9697Z"
                    fill="#263238"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_613_2663">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                type="text"
                name="vk_link"
                value={userData.vk_link}
                onChange={handleInputChange}
              />
            </div>
            <div className="social_urls">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_613_4731)">
                  <path
                    d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2417 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2417 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z"
                    fill="#263238"
                  />
                  <path
                    d="M7.24213 15.8311C11.9071 13.7991 15.0171 12.4593 16.5721 11.8121C21.0171 9.96381 21.9396 9.64281 22.5421 9.63193C22.6746 9.62981 22.9696 9.66256 23.1621 9.81818C23.3221 9.94943 23.3671 10.1269 23.3896 10.2516C23.4096 10.3761 23.4371 10.6598 23.4146 10.8813C23.1746 13.4113 22.1321 19.5508 21.6021 22.3846C21.3796 23.5836 20.9371 23.9856 20.5096 24.0248C19.5796 24.1103 18.8746 23.4108 17.9746 22.8211C16.5671 21.8978 15.7721 21.3233 14.4046 20.4226C12.8246 19.3816 13.8496 18.8093 14.7496 17.8743C14.9846 17.6296 19.0796 13.9058 19.1571 13.5681C19.1671 13.5258 19.1771 13.3683 19.0821 13.2853C18.9896 13.2021 18.8521 13.2306 18.7521 13.2531C18.6096 13.2851 16.3621 14.7721 12.0021 17.7138C11.3646 18.1523 10.7871 18.3661 10.2671 18.3548C9.69713 18.3426 8.59713 18.0318 7.77963 17.7663C6.77963 17.4406 5.98213 17.2683 6.05213 16.7151C6.08713 16.4271 6.48463 16.1323 7.24213 15.8311Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_613_4731">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                type="text"
                name="tg_link"
                value={userData.tg_link}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="phone_balls_yk2">
            <div className="rich_scholar">Для школьников</div>
            <h2 className="acc_phone_num">Класс</h2>
            <input
              type="text"
              name="class_school"
              value={userData.class_school}
              onChange={handleInputChange}
            />
          </div>
          <div className="phone_balls_yk2">
            <h2 className="acc_phone_num">Школа</h2>
            <input
              type="text"
              name="school"
              value={userData.school}
              onChange={handleInputChange}
            />
          </div>
          <div className="phone_balls_yk2">
            <h2 className="acc_phone_num">Город</h2>
            <input
              type="text"
              name="city"
              value={userData.city}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <button className="save_redact" type="submit" onClick={handleSubmit}>
          Сохранить
        </button>
      </div>{" "}
    </div>
  );
}

export default Redact;
