import GroupMembers from "../groupMembers/groupMembers";
import HeaderAcc from "../navbaracc/navbaracc";

const GroupMembersPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <GroupMembers />
      </div>
    </div>
  );
};

export default GroupMembersPage;
