import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

function Header() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [pkFlag, setPkFlag] = useState(false);
  const [Admin, setAdmin] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const pkCheck = () => {
    const userDataString = localStorage.getItem("user_data");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const pk = userData?.pk;
      setPkFlag(pk != null);
      const admin = userData?.is_admin;
      setAdmin(admin != null);
    } else {
      setPkFlag(false);
      setAdmin(false);
    }
  };

  useEffect(() => {
    pkCheck();
  }, []);

  return (
    <section className="nav_section">
      <header>
        <div className="container">
          <div className="row">
            <nav>
              <ul className={`menu ${isMenuActive ? "menu_active" : ""}`}>
                <div className="col-md-1">
                  <li className="menu_item">
                    <Link
                      to="https://infochemistry.ru/"
                      className="menu_link"
                      target="_blank"
                    >
                      <img
                        className="logo"
                        src={process.env.PUBLIC_URL + "/img/logo.png"}
                        alt="ERROR"
                      />
                    </Link>
                  </li>
                </div>
                <div className="col-md-2">
                  <li className="menu_item">
                    <Link to="/" className="menu_link_bolder_infochem">
                      InfochemistryEdu
                    </Link>
                  </li>
                </div>
                <div className="col-md-6 ">
                  <div className="menu_light">
                    <li className="menu_item">
                      <Link to="/" className="menu_link">
                        Главная
                      </Link>
                    </li>
                    <li className="menu_item">
                      <Link to="/tbvideos" className="menu_link">
                        Материалы⠀
                        <svg
                          className="arr_nav"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                          fill="none"
                        >
                          <path
                            d="M11 1.5L6.58926 5.91075C6.26382 6.23618 5.73618 6.23618 5.41074 5.91075L1 1.5"
                            stroke="#272D37"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li className="menu_item">
                      <Link
                        to="https://infochemistry.ru/"
                        className="menu_link"
                        target="_blank"
                      >
                        НОЦ Инфохимии
                      </Link>
                    </li>
                    <li className="menu_item">
                      <Link to="/" className="menu_link">
                        ХимСофт
                      </Link>
                    </li>
                  </div>
                </div>
                <div className="col-md-2">
                  <li className="menu_item_registration">
                    <Link to="/registration" className="menu_link_bolder_reg">
                      <span className="registration_button_1">Регистрация</span>
                    </Link>
                  </li>
                  {pkFlag ? (
                    <li className="menu_item_login">
                      <Link
                        to={`${Admin ? "/professor" : "/account"}`}
                        className="menu_link_bolder"
                      >
                        <span className="login_button_1">Войти</span>
                      </Link>
                    </li>
                  ) : (
                    <li className="menu_item_login">
                      <Link to="/login" className="menu_link_bolder">
                        <span className="login_button_1">Войти</span>
                      </Link>
                    </li>
                  )}
                </div>
              </ul>
              <div
                className={`hamburger ${
                  isMenuActive ? "hamburger_active" : ""
                }`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="infochem_again">
                <h1 className="infochem_again_text">InfochemistryEdu</h1>
              </div>
              <Link to="/registration">
                <div className="login_button_2">
                  <span className="login_button_2_text">Регистрация</span>{" "}
                </div>
              </Link>
            </nav>
          </div>
        </div>
      </header>
    </section>
  );
}

export default Header;
