import StudentsListParsed from "../bachelors1/bachelors1";
import HeaderAcc from "../navbaracc/navbaracc";

const StudentsListParsedPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <StudentsListParsed />
      </div>
    </div>
  );
};

export default StudentsListParsedPage;
