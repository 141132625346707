import Grouping from "../grouping/grouping";
import HeaderAcc from "../navbaracc/navbaracc";

const GroupingPage = () => {
  return (
    <div className="header_acc">
      <HeaderAcc />
      <div className="acc_page">
        <Grouping />
      </div>
    </div>
  );
};

export default GroupingPage;
